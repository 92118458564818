import {
  statusData,
  statusDataForSales,
} from 'layouts/task-order-details/config';
import { formatDateMMDDYYYY, getUnixTime } from 'utils/helpers/date';
import { getFileStatus } from 'utils/helpers/files';
import { requiredValidator } from 'utils/validation';
import { object } from 'yup';

export default ({
  isCEO,
  isExpired,
  isUserSales,
  taskOrderTitle,
  taskOrderEndDate,
  isManuallyDeactivated,
  hasPermissionsToDelete,
  hasPermissionsToADDFiles,
  hasPermissionsTosSendForSignature,
}) => [
  {
    type: 'table',
    withUploading:
        hasPermissionsToADDFiles && (isCEO || !isManuallyDeactivated),
    noDataMessage: 'This section currently contains no TO documents.',
    tableCssRules: 'min-height: calc(50vh - 30rem);',
    fileGeneratorConfig: {
      title: 'Generate new TO document',
      fields: [
        {
          type: 'text',
          name: 'title',
          label: 'Title',
        },
      ],
      validationSchema: object().shape({
        title: requiredValidator(),
      }),
      initialValues: {
        title: taskOrderTitle,
      },
    },

    fileLoaderConfig: {
      title: 'Upload new TO document',
      fields: [
        {
          type: 'text',
          name: 'documentTitle',
          label: 'Title',
        },
        {
          type: 'select',
          name: 'documentStatus',
          label: 'Status',
          ...statusData,
          ...(isUserSales && statusDataForSales),
        },
      ],
      validationSchema: object().shape({
        documentTitle: requiredValidator().max(50, 'Title max length is 50'),
      }),
      initialValues: {
        documentTitle: taskOrderTitle,
        documentStatus: false,
      },
    },

    content: [
      {
        title: 'Documents',
        idKey: 'fileId',
        dataKey: 'files',

        rowStatusGetter: ({ taskOrderId, title }) => ({
          // TODO
          id: taskOrderId,
          isActive: !isExpired,
          hasWarning: false,
          name: title,
        }),

        rules: {
          css: {
            gridTemplateConfig: hasPermissionsTosSendForSignature ?
              `grid-template-columns: minmax(auto, 39fr) minmax(auto, 12fr) minmax(auto, 12fr) minmax(auto, 15fr) minmax(auto, ${
                isManuallyDeactivated ? 55 : 27
              }fr);` :
              'grid-template-columns: minmax(auto, 30fr) minmax(auto, 12fr) minmax(auto, 12fr) minmax(auto, 37fr);',
          },
        },
        tableName: 'toDocuments',
        dataTemplate: [
          {
            columnName: 'Title',
            storeKey: '',
            type: 'text',
            valueGetter: ({ title, downloadUrl, downloadSignedUrl }) => ({
              // TODO
              value: title,
              type: downloadSignedUrl || downloadUrl ? 'link' : 'text',
              componentProps: {
                data: title,
                href: downloadSignedUrl || downloadUrl,
                withBlank: true,
              },
            }),
          },
          {
            columnName: 'Upload Date',
            headerProps: {
              cssRules: 'margin-right: 3rem;',
            },
            valueGetter: ({ uploadedDate }) => ({
              isDate: true,
              unixValue: getUnixTime(uploadedDate),
              type: 'text',
              componentProps: {
                data: uploadedDate ? formatDateMMDDYYYY(uploadedDate) : 'N/A',
                isEmpty: !uploadedDate,
              },
            }),
          },
          {
            columnName: 'Effective Date',
            headerProps: {
              cssRules: 'margin-right: 3rem;',
            },
            valueGetter: ({ effectiveDate }) => ({
              isDate: true,
              unixValue: getUnixTime(effectiveDate),
              type: 'text',
              componentProps: {
                data: effectiveDate ?
                  formatDateMMDDYYYY(effectiveDate) :
                  'N/A',
                isEmpty: !effectiveDate,
              },
            }),
          },
          {
            columnName: 'status',
            valueGetter: ({ isSigned, isGenerated }) => {
              const status = getFileStatus({
                isSigned,
                isGenerated,
                effectiveDate: taskOrderEndDate,
              });

              return {
                value: status,
                type: 'text',
              };
            },
          },
          {
            columnName: 'action',
            withoutControls: true,
            shouldntBeRendered: !hasPermissionsTosSendForSignature,
            valueGetter: ({
              isSigned,
              title,
              fileId,
              isRequestToSignSent,
            }) => {
              const message = {
                value: 'Sent for Signature',
                type: 'text',
                shouldntBeRendered: !hasPermissionsTosSendForSignature,
              };

              const sendForSignatureConfig = {
                value: 'Send for signature',
                type: 'action',
                shouldntBeRendered: !hasPermissionsTosSendForSignature,

                componentProps: {
                  data: 'Send for signature',
                  actionName: 'changeCurrentModal',
                  withBody: true,
                  isHidden: false,
                  actionArguments: {
                    currentModal: 'SEND_FOR_SIGNATURE_MODAL',
                    fileId,
                  },
                },
              };

              const deleteButton = {
                value: 'Delete',
                type: 'action',
                cssRules: 'margin-left: auto;',
                componentProps: {
                  isHidden: isSigned || (!isCEO && isManuallyDeactivated),
                  data: 'Delete',
                  actionName: 'changeCurrentModal',
                  actionArguments: {
                    currentModal: 'CONFIRM_ACTION_MODAL',
                    content: [
                      {
                        type: 'title',
                        data: `Delete “${title}”?`,
                      },
                      {
                        type: 'description',
                        data: 'This file will be permanently deleted and cannot be restored.',
                      },
                    ],
                    acceptActionName: 'deleteTaskOrderDocument',
                    acceptActionTitle: 'Delete',
                    acceptActionArguments: {
                      fileId,
                    },
                  },
                },
              };

              const noComponent = {
                shouldntBeRendered: true,
              };
              const getIsNeedSign = (unitsIsTrue, unitsIsFalse) => isSigned || isManuallyDeactivated ?
                unitsIsTrue :
                unitsIsFalse;

              return hasPermissionsToDelete ?
                getIsNeedSign(
                  [deleteButton],
                  [
                    isRequestToSignSent ? message : sendForSignatureConfig,
                    deleteButton,
                  ]
                ) :
                getIsNeedSign(
                  noComponent,
                  isRequestToSignSent ? message : sendForSignatureConfig
                );
            },
          },
        ],
      },
    ],
  },
];
