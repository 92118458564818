import React, { memo, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import IconBold from 'assets/icons/bold.svg';
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg';
import IconItalic from 'assets/icons/italic.svg';
import IconListBullet from 'assets/icons/list-bullet.svg';
import IconListNumbered from 'assets/icons/list-numbered.svg';
import IconStrikethrough from 'assets/icons/strikethrough.svg';
import IconUnderlined from 'assets/icons/underlined.svg';

import classNames from 'classnames';

import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ActionButton from 'elements/action-button';
import htmlToDraft from 'html-to-draftjs';
import { isEqual } from 'lodash';
import { Editor as WysiwygEditor } from 'react-draft-wysiwyg';
import styled, { css } from 'styled-components';

import styles from './styles.module.scss';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const Editor = memo(
  ({ isReset, onChange, isDisabled, isError, value, cssRules, placeholder }) => {
    const init = () => {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        return EditorState.createWithContent(contentState);
      }
      return EditorState.createEmpty();
    };

    const [state, setState] = useState(init);
    const [focus, setFocus] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const handleToggleEdit = () => {
      setIsActive((prevState) => !prevState);
    };

    const onEditorStateChange = (editorState) => {
      const oldContent = convertToRaw(state.getCurrentContent());
      const newContent = convertToRaw(editorState.getCurrentContent());
      const contentState = editorState.getCurrentContent();

      const markup = draftToHtml(
        newContent,
      );

      const replacedMarkup = markup.replace(/<br>/g, '');
      setState(editorState);

      if (!isEqual(oldContent, newContent)) {
        onChange(contentState.hasText() ? replacedMarkup : '');
      }
    };

    useEffect(() => {
      if (!isReset && !focus) {
        setState(init);
      }
    }, [isReset, focus]);

    return (
      <StyledDiv
        className={classNames(styles.editor, {
          [styles.error]: isError,
          [styles.disabled]: isDisabled,
          [styles.active]: isActive,
        })}
        cssRules={cssRules}
      >
        <WysiwygEditor
          editorState={state}
          toolbar={{
            options: ['inline', 'list'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
              bold: {
                icon: IconBold,
              },
              italic: {
                icon: IconItalic,
              },
              underline: {
                icon: IconUnderlined,
              },
              strikethrough: {
                icon: IconStrikethrough,
              },
            },
            list: {
              options: ['unordered', 'ordered'],
              unordered: {
                icon: IconListBullet,
              },
              ordered: {
                icon: IconListNumbered,
              },
            },
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          toolbarClassName={styles.toolbar}
          toolbarHidden={isDisabled ? true : !isActive}
          onEditorStateChange={onEditorStateChange}
          handlePastedText={() => false}
          placeholder={!focus && placeholder}
        />
        {!isDisabled && (
          <ActionButton className={styles.edit} onClick={handleToggleEdit} data={<IconEdit />} />
        )}
      </StyledDiv>
    );
  }
);

const StyledDiv = styled.div`
  ${({ cssRules }) => css`${cssRules}`}`;

Editor.propTypes = {
  isError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isReset: PropTypes.bool,
  isDisabled: PropTypes.bool,
  cssRules: PropTypes.string,
};

Editor.defaultProps = {
  value: '',
  isReset: false,
  isDisabled: false,
  isError: false,
  placeholder: '',
  cssRules: '',
};
