import { get } from 'lodash';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { getUnixTime } from 'utils/helpers/date';

import { DEFAULT_HRS_DAY, DEFAULT_SELECT_OPTION } from './constants';

momentTZ.tz.setDefault('UTC');

export const getDefaultHrsDay = (dateHired) => dateHired ? DEFAULT_HRS_DAY.EIGHT :
  DEFAULT_HRS_DAY.SIX;

export const getFormattedDate = (date) => moment(date).format('L');

export const hasSalary = (record) => ['salUsd', 'salPln', 'salRur'].some((salaryType) => !!get(record, salaryType, null));

export const shouldShowInfoCard = (inEditMode, isHistoryRecord, hasPermissionsToUpdate, record, isDevCenterDirector, isCEO, isHr, isNew) => {
  if (!hasPermissionsToUpdate || (isHr && !isNew)) {
    return true;
  }

  const approvedDd = get(record, 'approvedDd', false);
  const approvedCeo = get(record, 'approvedCeo', false);
  return !inEditMode && (isHistoryRecord || (!hasPermissionsToUpdate && approvedDd) || (isDevCenterDirector && approvedDd) || (!isCEO && (approvedDd && !approvedCeo)));
};

export const generateEmptyRecord = ({ title, devcenter, plLevel, dateHired }) => ({
  remarks: '',
  seniority: '',
  recordId: '',
  probation: '',
  validFrom: '',
  seniorityInt: '',
  plLevel: plLevel || '',
  hrsDay: getDefaultHrsDay(dateHired),
  title: title || '',
  approvedCeo: false,
  approvedDd: false,
  salUsd: null,
  salPln: null,
  salRur: null,
  approvedHrm: false,
  rejectedCeo: false,
  rejectedDd: false,
  approvedDdDate: null,
  approvedCeoDate: null,
  rejectedDdDate: null,
  rejectedCeoDate: null,
  devcenter: devcenter || '',
  templateOwnerId: '',
});

const getSortedFullHistory = (history = []) => history.sort((left, right) => {
  const { dateStart: leftDateStart, validFrom: leftValidFrom } = left;
  const { dateStart: rightDateStart, validFrom: rightValidFrom } = right;

  return (
    getUnixTime(rightValidFrom || rightDateStart) -
      getUnixTime(leftValidFrom || leftDateStart)
  );
});

export const generateFullHistory = (records = [], archivedRecords = [], dates) => {
  if (records.length === 0 && archivedRecords.length === 0) return [];
  const history = [...records, ...archivedRecords];
  const { startDate, endDate, rehireDate, dismissalDate } = dates;

  if (startDate) {
    history.push({
      onLeave: true,
      dateStart: get(startDate, 'value', null),
      endDate: get(endDate, 'value', null),
      historyId: get(startDate, 'historyId', null),
    });
  }

  if (dismissalDate) {
    history.push({
      isDismiss: true,
      dateStart: get(dismissalDate, 'value', null),
      historyId: get(dismissalDate, 'historyId', null),
    });
  }

  if (rehireDate) {
    history.push({
      isRehire: true,
      dateStart: get(rehireDate, 'value', null),
      historyId: get(rehireDate, 'historyId', null),
    });
  }

  if (
    !get(history, '[0].recordId', false) &&
    !get(history, '[0].historyId', false)
  ) {
    return [history[0], ...getSortedFullHistory(history.slice(1))].filter((item) => item);
  }

  return getSortedFullHistory(history).filter((item) => item);
};

export const generateDevCentersListForSelect = (devCenters) => [
  DEFAULT_SELECT_OPTION,
  ...devCenters.forSelectShortNames,
];

export const getDevCenterShortName = (devCenters, currentDevCenter) => devCenters.byIdShortName[currentDevCenter];

export const getFilteredDevCentersForSelect = (devCenters, userGroup, devCentersBySystemRole) => {
  let accessDevCenters = null;
  devCentersBySystemRole.forEach((item) => {
    if (item.role === userGroup) {
      accessDevCenters = item.devcenterList;
    }
  });

  if (!accessDevCenters) {
    return devCenters;
  }
  return devCenters.filter((devCenter) => accessDevCenters.includes(devCenter.value) || devCenter.value === '');
};
export const generateEndOfProbationByValidFrom = (probation, validFrom) => Boolean(validFrom) && probation ? moment(validFrom)
  .add(3, 'month')
  .subtract(1, 'day')
  .format('YYYY-MM-DD') : null;

export const getDevCentersInRange = (formFromDate, formThroughDate, devCentersByDate) => {
  const fromDate = moment(formFromDate, 'YYYY-MM-DD');
  const throughDate = moment(formThroughDate, 'YYYY-MM-DD');

  const sortedDates = devCentersByDate
    .sort((a, b) => moment(a.date).diff(moment(b.date)));

  const periods = sortedDates.map((item, index) => {
    const startDate = moment(item.date);
    const endDate = sortedDates[index + 1] ? moment(sortedDates[index + 1].date) : null;

    return {
      startDate,
      endDate,
      devcenter: item.devcenter,
    };
  });

  const filteredPeriods = periods.filter(({ startDate, endDate }) => (
    startDate.isBefore(throughDate) &&
      (!endDate || endDate.isAfter(fromDate))
  ));

  return filteredPeriods.map(({ startDate, endDate, devcenter }) => ({
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
    devcenter,
  }));
};
export const getValidDatesInRange = (
  formFromDate,
  formThroughDate,
  devCentersInRange,
  holidayDatesByDevcenter
) => {
  const validDates = [];

  devCentersInRange.forEach((item) => {
    const devcenterId = item.devcenter;
    const devcenterStartDate = moment.max(moment(item.startDate), moment(formFromDate));
    const devcenterEndDate = item.endDate ? moment.min(moment(item.endDate), moment(formThroughDate)) : moment(formThroughDate);

    if (holidayDatesByDevcenter[devcenterId]) {
      holidayDatesByDevcenter[devcenterId].holidayDates.forEach((date) => {
        const parsedDate = moment(date);
        if (parsedDate.isBetween(devcenterStartDate, devcenterEndDate, 'days', '[]')) {
          validDates.push(parsedDate.toISOString());
        }
      });
    }
  });

  return Array.from(new Set(validDates));
};
