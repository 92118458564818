import { colorSecondaryGrayLight06 } from 'assets/styles/variables';

export const controlsGridClass = (hasPermissionsToUpdate = false) => `controls additional-controls ${hasPermissionsToUpdate ? 'additional-controls' : ''}`;

export const generatePartialGridTemplateColumnsConfig = (hasPermissionsToUpdate = false, salaryHidden = false) => hasPermissionsToUpdate ?
  `minmax(0, 120fr) minmax(0, 70fr) minmax(0, 110fr) minmax(0, 70fr) minmax(0, 53fr) minmax(0, 43fr) minmax(0, 38fr) minmax(0, 43fr) minmax(0, 111fr) ${salaryHidden ? '' : 'minmax(0, 68fr) minmax(0, 68fr) minmax(0, 68fr)'}` :
  'minmax(0, 146fr) minmax(0, 78fr) minmax(0, 247fr) minmax(0, 86fr) minmax(0, 69fr) minmax(0, 59fr) minmax(0, 58fr) minmax(0, 50fr) minmax(0, 70fr)';

export const getGridTemplateColumnsConfig = (hasPermissionsToUpdate, salaryHidden) => {
  const columnsSize = salaryHidden ? 'minmax(0, 100fr) minmax(0, 100fr)' : 'minmax(0, 130fr) minmax(0, 130fr)';
  return hasPermissionsToUpdate ?
    `${generatePartialGridTemplateColumnsConfig(hasPermissionsToUpdate, salaryHidden)} minmax(0, 118fr) ${columnsSize}` :
    `${generatePartialGridTemplateColumnsConfig(hasPermissionsToUpdate)} minmax(0, 131fr) minmax(0, 187fr)`;
};

export const getGridTemplateAreas = (hasPermissionsToUpdate, salaryHidden) => {
  const areasCount = salaryHidden ? 9 : 12;
  const areasCountWithoutPermissions = 9;
  const recordGrid = 'record '.repeat(hasPermissionsToUpdate ? areasCount : areasCountWithoutPermissions).trim();
  const toastifyGrid = 'toastify '.repeat(hasPermissionsToUpdate ? areasCount : areasCountWithoutPermissions).trim();

  return `
    "${recordGrid} ${controlsGridClass(hasPermissionsToUpdate)}"
    "${toastifyGrid} ${controlsGridClass(hasPermissionsToUpdate)}"
  `;
};

export const getCssGrids = (hasPermissionsToUpdate, salaryHidden) => ({
  gridTemplateColumns: getGridTemplateColumnsConfig(hasPermissionsToUpdate, salaryHidden),
  gridTemplateAreas: getGridTemplateAreas(hasPermissionsToUpdate, salaryHidden),
});

export const getContentCss = {
  dataCss: `
    padding: 0 1.6rem;
    font-size: 1.4rem;
    line-height: 3.2rem;
  `,
  contentCss: `
    margin-top: 0.4rem;
    background-color: ${colorSecondaryGrayLight06};
  `,
};

export const getFormStyles = (cssGrids) => `
        display: grid;
        grid-column-gap: 0.8rem;
        grid-template-areas: ${cssGrids.gridTemplateAreas};
        grid-template-columns: ${cssGrids.gridTemplateColumns};
        border-bottom: 1px solid #bbbdc0;
      `;

export const getAdditionalControlsCommonCssGrid = (hasPermissionsToUpdate) => `
    display: grid;
    align-content: flex-start;
    grid-area: additional-controls;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr ${hasPermissionsToUpdate ? '1fr' : ''};
    padding: 1.9rem ${hasPermissionsToUpdate ? '0' : '2.4rem'} 2.4rem;
    background-color: rgba(187,189,192, 0.3);
    padding: 2.8rem 1rem;
  `;
