import IconChecked from 'assets/img/icon-checkbox-checked.svg';
import IconUnchecked from 'assets/img/icon-checkbox.svg';
import {
  colorPrimary,
  colorSecondaryText,
  colorSecondaryGray,
  colorSecondaryGrayDark,
  colorSecondaryGrayLight,
} from 'assets/styles/variables';
import {
  UNIT_TYPES as GRID_TEMPLATE_UNITS_TYPES,
} from 'components/grid-template/constants';

import { UNITS_TYPES, COLUMN_TYPES } from 'core/constants';
import {
  ADDITIONAL_COLUMNS_NONE_ENABLED,
  ADDITIONAL_COLUMNS_ALL_ENABLED,
} from 'core/employees-reviews/constants';
import { MODALS } from 'core/modal-conductor/constants';
import hash from 'hash-sum';
import { toNumber, get } from 'lodash';
import moment from 'moment';

const { SEND_REVIEW_MODAL } = MODALS;
const getMonthPickerCssRules = ({ content }) => `
  && {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    border: none;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${colorPrimary};
    & path {
      fill: ${colorPrimary};
    }
    &:before {
      margin-right: 1rem;
      content: '${content}';
      font-size: 1.4rem;
      line-height: 1.8rem;
      white-space: nowrap;
      color: ${colorSecondaryGray};
    }
  }
`;

export const employeesReviewsReportModelGetter = ({
  selectedEmployee,
  additionalColumns,
  groupedEmployeesReviews,
  employeesData,
  activeFilters = [],
  dateFrom,
  dateTo,
}) => {
  const additionalColumnsList = Object.entries(additionalColumns);
  let additionalColumnsForPdfReport;
  let hasExcludedColumns = false;

  additionalColumnsList.forEach(([name, isActive]) => {
    if (isActive) {
      if (additionalColumnsForPdfReport) {
        additionalColumnsForPdfReport.push(name);
      } else {
        additionalColumnsForPdfReport = [name];
      }
    }
    hasExcludedColumns = hasExcludedColumns || !isActive;
  });
  const pdfFields = {
    devstaffId: selectedEmployee,
    startDate: dateFrom ? dateFrom.clone().startOf('week').format('YYYY-MM-DD') : null,
    endDate: dateTo ? dateTo.clone().endOf('week').format('YYYY-MM-DD') : null,
    additionalColumns: additionalColumnsForPdfReport,
  };
  const { map } = employeesData;
  const employeeFullName = get(map, `${selectedEmployee}.label`, 'employee');
  const hasReviews = !!groupedEmployeesReviews.length;
  const hasActiveFilters = !!activeFilters.length;
  const groupId = 'reviewsReport';
  return ({
    isSingleTab: true,
    additionalDataGetter: () => ({
      filters: [{
        isActive: true,
        name: 'Activity',
        onChange: (array) => array.filter((item) => item.isActive),
        title: 'Display only Active Staff',
      }],
      controls: selectedEmployee && hasReviews && [{
        title: 'PDF',
        actionName: 'getPdfReviews',
        actionArguments: pdfFields,
      }, {
        title: 'Send report',
        actionName: 'changeCurrentModal',
        actionArguments: {
          currentModal: SEND_REVIEW_MODAL,
          employeeFullName,
          employeesData,
          pdfFields,
        },
      }],
      filtersWrapperCssRules: `
        display: grid;
        grid-template-columns: max-content 22rem 15rem max-content;
      `,
      controlsCssRules: `
        &&& {
          display: grid;
          grid-template-columns: 1fr minmax(auto, 18.4rem) minmax(auto, 18.4rem);
          grid-row-gap: 0.8rem;
          grid-auto-rows: minmax(3.2rem, auto);
          flex-direction: column;
          padding-top: 0.8rem;
          padding-bottom: 1.6rem;
          font-size: 1.5rem;
        }
      `,
      clearButtonCssRules: `&& {
        grid-column: 3;
        margin-top: 0.8rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: ${colorPrimary};
        white-space: nowrap;
        text-align: right;
      }`,
      filterSelectCssRules: `
        && {
          flex-grow: 1;
          box-sizing: border-box;
          width: auto;
          max-height: 3.4rem;
          padding-top: 0.8rem;
          padding-right: 3.2rem;
          color: ${colorPrimary};
          .filter-select {
            width: auto;
            max-width: 100%;
            height: auto;
            &__menu {
              min-width: 40rem;
            }
            &__single-value {
              margin: 0 0.7rem 0 0;
              max-width: unset;
            }
            &__single-value__label {
              font-size: 1.6rem;
              color: ${colorPrimary};
            }
            &__placeholder {
              padding: 0;
              font-size: 1.6rem;
              line-height: 1.8rem;
              color: ${colorPrimary};
            }
            &__control {
              font-size: 1.6rem;
              line-height: 1.6rem;
            }
            &__control--is-focused {
              outline: none !important;
            }
            &__dropdown-indicator {
              padding-bottom: 0.3rem;
              path {
                fill: ${colorPrimary};
              }
            }
          }
        }
      `,
      checkboxCssRules: `
        && {
          font-size: 1.3rem;
          line-height: 1.6rem;
          margin: 0.8rem 0 0 3.2rem;
          padding-left: 2.15rem;

          &::before {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      `,
      filterOptionsStyles: {
        option: (base, state) => {
          const styles = get(state, 'data.styles', {});
          return ({
            ...base,
            ...styles,
          });
        },
      },
      fromMonthPickerCssRules: getMonthPickerCssRules({ content: 'Date Range:' }),
      toMonthPickerCssRules: getMonthPickerCssRules({ content: '— ' }),
      controlsWrapperCssRules: `
        position: sticky;
        top: 0;
        z-index: 2;
      `,
      selectCssRules: `
        && {
          padding-top: 0.4rem;
          color: ${colorPrimary};

         .filter-select {
           width: auto;
          height: auto;
           &__multi-value__label {
             color: ${colorPrimary};
           }
           &__placeholder {
             padding-left: 0;
             color: ${colorPrimary};
           }
           &__control--is-focused {
             outline: none !important;
           }
           &__dropdown-indicator {
            path {
               fill: ${colorPrimary};
             }
           }
         }
       }
      `,
    }),
    dataTemplate: selectedEmployee && hasReviews ? [
      {
        type: UNITS_TYPES.SINGLE_ROW,
        cssRules: `
          display: flex;
          margin: 0;
          padding: 0;
          font-size: 2.2rem;
        `,
        rowData: [
          {
            type: GRID_TEMPLATE_UNITS_TYPES.ACTION,
            componentProps: {
              data: 'All',
              actionName: 'updateColumnsState',
              actionArguments: Object.values(additionalColumns).every((isActive) => isActive) ?
                ADDITIONAL_COLUMNS_NONE_ENABLED :
                ADDITIONAL_COLUMNS_ALL_ENABLED,
              cssRules: `
              && {
                position: relative;
                margin-right: 2.4rem;
                padding-left: 2.3rem;
                font-size: 1.3rem;
                line-height: 1.6rem;
                font-weight: 400;
                text-decoration: none;
                color: ${hasExcludedColumns ? colorSecondaryGrayLight : colorPrimary}
                &:before {
                  position: absolute;
                  top: 50%;
                  left: 0;
                  display: block;
                  width: 1.5rem;
                  height: 1.5rem;
                  background-image: url(${hasExcludedColumns ? IconUnchecked : IconChecked});
                  background-repeat: no-repeat;
                  background-size: 100%;
                  content: "";
                  transform: translateY(-50%);
                }
              }
            `,
            },
          },
          additionalColumnsList.map(([column, isActive]) => {
            let dataTitle;
            switch (column) {
              case 'reviewby': {
                dataTitle = 'Review by';
                break;
              }
              case 'project': {
                dataTitle = 'Project';
                break;
              }
              case 'role': {
                dataTitle = 'Role';
                break;
              }
              default:
                dataTitle = '';
            }

            return ({
              type: GRID_TEMPLATE_UNITS_TYPES.ACTION,
              componentProps: {
                data: dataTitle,
                actionName: 'updateColumnsState',
                actionArguments: {
                  ...additionalColumns,
                  [column]: !isActive,
                },
                cssRules: `
              && {
                position: relative;
                margin-right: 1.6rem;
                padding-left: 2.3rem;
                font-size: 1.3rem;
                line-height: 1.6rem;
                font-weight: 400;
                text-decoration: none;
                color: ${isActive ? colorPrimary : colorSecondaryGrayLight}
                &:before {
                  position: absolute;
                  top: 50%;
                  left: 0;
                  display: block;
                  width: 1.5rem;
                  height: 1.5rem;
                  background-image: url(${isActive ? IconChecked : IconUnchecked});
                  background-repeat: no-repeat;
                  background-size: 100%;
                  content: "";
                  transform: translateY(-50%);
                }
              }
            `,
              },
            });
          }),
          {
            type: 'groupController',
            cssRules: `
              && {
                flex-grow: 1;
                .action-button {
                  font-size: 1.4rem;
                  line-height: 1.8rem;
                }
              }
            `,
            componentProps: {
              groupId,
              cssRules: `
                && {
                  margin-left: auto;
                }
              `,
            },
          },
        ],
      },
      ...groupedEmployeesReviews.map(([key], index) => {
        const {
          role: withRoleColumn,
          project: withProjectColumn,
          reviewby: withReviewbyColumn,
        } = additionalColumns;
        return ({
          type: UNITS_TYPES.TABLE,
          currentFilter: 'employeesReviewsReport',
          content: [{
            idKey: 'projectId',
            dataKey: `${key}.rows`,
            tableName: 'reviews',
            preventRenderEmpty: true,
            summaryConfig: {
              label: `${key}`,
              groupId,
              isDefaultExpanded: !index,
              cssRules: `
                position: sticky;
                top: 10.9rem;
                min-height: 4.8rem;
                font-size: 1.6rem;
                padding: 0 1.6rem;
                border-top: 1px solid ${colorSecondaryGrayLight};
                background-color: #eaeaea;
                color: ${colorSecondaryText};
                z-index: 1;
              `,
            },
            preventScrolling: true,
            rowStatusGetter: (row) => {
              const id = hash(row);
              return ({
                id,
              });
            },
            rules: {
              css: {
                gridTemplateConfig: `grid-template-columns: minmax(auto, 150fr) minmax(auto, 170fr) minmax(auto, 450fr) ${withReviewbyColumn ? 'minmax(auto, 160fr)' : ''}${withProjectColumn ? 'minmax(auto, 200fr)' : ''} ${withRoleColumn ? 'minmax(auto, 150fr)' : ''};`,
              },
            },
            dataTemplate: [
              {
                columnName: 'Week',
                valueGetter: ({ week }) => {
                  const date = moment().set({ year: key, week });
                  const startOfWeek = date.clone().startOf('week').format('DD MMM');
                  const endOfWeek = date.clone().endOf('week').format('DD MMM');

                  return [
                    {
                      type: COLUMN_TYPES.TEXT,
                      sortBy: true,
                      componentProps: {
                        data: `0${week}`.slice(-2),
                        cssRules: `
                        width: auto;
                        padding: 0 0.4rem 0 1.6rem;
                        font-size: 1.3rem;
                        font-weight: 500;
                        color: ${colorPrimary};
                        &:after {
                          content: ":";
                          color: ${colorSecondaryText};
                        }
                      `,
                      },
                    }, {
                      type: COLUMN_TYPES.TEXT,
                      componentProps: {
                        data: `${startOfWeek} - ${endOfWeek}`,
                        cssRules: `
                      font
                        letter-spacing: -0.25px;
                      `,
                      },
                    },
                  ];
                },
              },
              {
                columnName: 'rating',
                valueGetter: ({ rating }) => [
                  {
                    type: 'rating',
                    cssRules: `
                    margin-top: 0.6rem;
                    padding: 0;
                  `,
                    componentProps: {
                      rating,
                      isViewMode: true,
                    },
                  },
                  {
                    type: COLUMN_TYPES.TEXT,
                    sortBy: true,
                    componentProps: {
                      data: `(${rating})`,
                      cssRules: `
                      margin-left: 1.6rem;
                      letter-spacing: 1.5px;
                      color: ${toNumber(rating) > 2 ? colorPrimary : colorSecondaryGrayDark};
                    `,
                    },
                  },
                ],
              },
              {
                columnName: 'Review',
                valueGetter: ({ review }) => ({
                  type: COLUMN_TYPES.TEXT,
                  componentProps: {
                    data: review,
                    isMultiline: true,
                    isHTML: true,
                    cssRules: 'box-sizing: border-box;',
                  },
                }),
              },
              {
                columnName: 'Review by',
                shouldntBeRendered: !withReviewbyColumn,
                valueGetter: ({ reviewBy, isReviewByPl }) => ({
                  type: COLUMN_TYPES.TEXT,
                  sortBy: true,
                  shouldntBeRendered: !withReviewbyColumn,
                  componentProps: {
                    isMultiline: true,
                    data: reviewBy,
                    cssRules: `
                      width: auto;
                      padding-right: 0.8rem;
                      ${isReviewByPl ? `
                        &:after {
                          ${reviewBy ? 'margin-left: 0.6rem;' : ''}
                          font-weight: 500;
                          color: ${colorPrimary};
                          content: "${reviewBy ? '/ ' : ''}PL";
                        }
                      ` : ''}
                    `,
                  },
                }),
              },
              {
                columnName: 'Project',
                shouldntBeRendered: !withProjectColumn,
                valueGetter: ({ projectName }) => ({
                  type: COLUMN_TYPES.TEXT,
                  shouldntBeRendered: !withProjectColumn,
                  componentProps: {
                    data: projectName,
                    isMultiline: true,
                    cssRules: 'box-sizing: border-box;',
                  },
                }),
              },
              {
                columnName: 'Role',
                shouldntBeRendered: !withRoleColumn,
                valueGetter: ({ role, isPl }) => ({
                  type: COLUMN_TYPES.TEXT,
                  sortBy: true,
                  shouldntBeRendered: !withRoleColumn,
                  componentProps: {
                    isMultiline: true,
                    data: role,
                    cssRules: `
                      width: auto;
                      padding-right: 0.8rem;
                      ${isPl ? `
                        &:after {
                          ${role ? 'margin-left: 0.6rem;' : ''}
                          font-weight: 500;
                          color: ${colorPrimary};
                          content: "${role ? '/ ' : ''}PL";
                        }
                      ` : ''}
                    `,
                  },
                }),
              },
            ],
          }],
        });
      }),
    ] : [{
      type: UNITS_TYPES.SCREEN_PLACEHOLDER,
      title: 'No results',
      description: `The current employee hasn't reviews ${hasActiveFilters ? 'matched selected criteria' : 'yet'}.`,
    }],
  });
};
