import React, { useRef, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import styled, { css } from 'styled-components';
import { removeHTMLTags } from 'utils/helpers/removeHTMLTags';

import './styles.scss';

const StyledContainer = styled.div`
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

const StyledContent = styled.div`
  width: 100%;
  padding-right: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ hasOverflow }) => hasOverflow ? `
    &:hover {
      &:after {
        position: absolute;
        left: 1ch;
        top: 1em;
        padding: 0.2rem 0.4rem;
        color: rgba(0, 0, 0, 0.87);
        content: attr(data-value);
        z-index: 1;
        opacity: 0.7;
      }
    }` : ''}

  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

const Text = ({ data, wrapperCssRules, cssRules, additionalData, isMultiline, isHTML, isEmpty, withCompleteText, title }) => {
  const [hasOverflow, setHasOverflow] = useState(false);
  const textContainer = useRef(null);

  useEffect(() => {
    const element = textContainer.current;

    setHasOverflow(withCompleteText &&
      (element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth));
  });

  return (
    <StyledContainer
      className={
        classNames(
          'plain-text',
          { 'plain-text--is-multi-line': isMultiline },
          { 'plain-text--isEmpty': isEmpty },
          { 'plain-text--isHTML': isHTML },
        )
      }
      title={title}
      cssRules={wrapperCssRules}
    >
      <StyledContent
        cssRules={cssRules}
        isMultiline={isMultiline}
        data-value={removeHTMLTags(data)}
        hasOverflow={hasOverflow}
        ref={textContainer}
      >
        {/* eslint-disable-next-line react/no-danger */}
        {isHTML ? <p dangerouslySetInnerHTML={{ __html: data }} /> : data}
      </StyledContent>

      {
        additionalData &&
        additionalData.map(({ cssRules: additionalDataCssRules, content }, index) => (
          <StyledContent
            key={index} // eslint-disable-line react/no-array-index-key
            cssRules={additionalDataCssRules}
          >
            {content}
          </StyledContent>
        ))
      }
    </StyledContainer>
  );
};

Text.propTypes = {
  wrapperCssRules: PropTypes.string,
  withCompleteText: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  title: PropTypes.string,
  additionalData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      cssRules: PropTypes.string,
    }),
  ])),
  isMultiline: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isHTML: PropTypes.bool,
  cssRules: PropTypes.string,
};

Text.defaultProps = {
  wrapperCssRules: '',
  data: '',
  cssRules: '',
  additionalData: null,
  isMultiline: false,
  isHTML: false,
  isEmpty: false,
  withCompleteText: false,
  title: '',
};

export default Text;
