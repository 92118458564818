import { isMatchWith } from 'lodash';
import moment from 'moment';

export const isPTMContract = (projectType) => projectType && projectType.toLowerCase() === 'ptm';
export const isTTMContract = (projectType) => projectType && projectType.toLowerCase() === 'ttm';
export const isODCContract = (projectType) => projectType && projectType.toLowerCase() === 'odc';

export const getRatesStatus = ({
  client,
  rateCard,
  templateTaskOrderRatecard,
}) => {
  const templateRatecard = client ? client.templateRatecard : {};

  const hasRatesCustomized = {
    hasClientRCCustomized: false,
    hasCurrentRCCustomized: false,
  };

  templateTaskOrderRatecard.forEach((rate, index) => {
    const templateClientSeniorityRates = templateRatecard ?
      templateRatecard.rates[index] || null :
      null;
    const currentTaskOrdererSeniorityRates = rateCard ?
      rateCard[index] || null :
      null;

    if (templateClientSeniorityRates) {
      const { hasClientRCCustomized } = hasRatesCustomized;
      const isCustomized = !isMatchWith(
        templateClientSeniorityRates,
        rate,
        (left, right) => Number.isInteger(left) ?
          Number(left) === Number(right) :
          left === right
      );
      hasRatesCustomized.hasClientRCCustomized =
        hasClientRCCustomized || isCustomized;
    }

    if (currentTaskOrdererSeniorityRates) {
      const { hasCurrentRCCustomized } = hasRatesCustomized;

      const isCustomized = !isMatchWith(
        currentTaskOrdererSeniorityRates,
        rate,
        (left, right) => Number.isInteger(left) ?
          Number(left) === Number(right) :
          left === right
      );

      hasRatesCustomized.hasCurrentRCCustomized =
        hasCurrentRCCustomized || isCustomized;
    }
  });

  const { hasCurrentRCCustomized, hasClientRCCustomized } = hasRatesCustomized;

  if (hasCurrentRCCustomized) {
    return {
      hasCurrentRCCustomized,
      hasClientRCCustomized,
      message: 'Customized Project Rates',
    };
  }

  if (hasClientRCCustomized) {
    return {
      hasCurrentRCCustomized,
      hasClientRCCustomized,
      message: 'Customized Client Rates',
    };
  }

  return {
    hasCurrentRCCustomized,
    hasClientRCCustomized,
    message: 'Default Rates',
  };
};

export const getTaskOrderStatus = ({
  isCEO,
  isActive,
  isExpired,
  isManuallyDeactivated,
  isSigned,
  taskOrderId,
  taskOrderEndDate,
}) => {
  const status = {
    notYetActive: !isActive && !isExpired && !isManuallyDeactivated,
    isActive,
    taskOrderId: taskOrderId || '',
    isExpired: false,
    title: 'Not yet active',
    actionTitle: '',
    sectionContent: 'This task order is not yet active.',
    additionalContent: 'The activation is not available.',

    onStatusChange: ({ onSubmit }) => {
      if (isManuallyDeactivated) {
        return onSubmit({
          fields: {
            isManuallyDeactivated: false,
            isActive: isSigned,
          },
        });
      }

      if (isExpired || (isActive && !isManuallyDeactivated)) {
        return onSubmit({
          fields: {
            isManuallyDeactivated: true,
          },
        });
      }

      if (isSigned && !isActive && !isManuallyDeactivated) {
        return onSubmit({
          fields: {
            isActive: true,
          },
        });
      }

      return null;
    },
  };

  if (isManuallyDeactivated && isActive) {
    return {
      ...status,
      isManuallyDeactivated: true,
      notYetActive: false,
      title: `Active (until ${moment(taskOrderEndDate).format('MM/DD/YYYY')})`,
      actionTitle: isCEO ? 'Reactivate' : '',
      sectionContent: 'This task order will be deactivated,',
      additionalContent: 'the client will be no longer billed for the project.',
    };
  }

  if (isManuallyDeactivated && !isActive) {
    return {
      ...status,
      isManuallyDeactivated: true,
      notYetActive: false,
      title: 'Deactivated',
      actionTitle: isCEO ? 'Reactivate' : '',
      sectionContent: 'This task order has been deactivated,',
      additionalContent: 'the client is no longer billed for the project.',
    };
  }

  if (isExpired) {
    return {
      ...status,
      isExpired: true,
      title: 'Expired',
      actionTitle: 'Deactivate',
      sectionContent:
        'This task order has expired and the client is no longer billed for the project,',
      additionalContent: 'would you like to deactivate it now?',
    };
  }

  if (!isActive && !isSigned) {
    return status;
  }

  if (!isActive && isSigned) {
    return {
      ...status,
      title: 'Signed',
      actionTitle: 'Activate',
      sectionContent: 'This task order is signed.',
      additionalContent: ' The activation is available.',
    };
  }

  return {
    ...status,
    title: 'Active',
    actionTitle: 'Deactivate',
    sectionContent:
      'This task order is active now, would you like to deactivate it?',
    additionalContent:
      'Once deactivated, the client will no longer be billed for this project. ',
    warningContent: 'In order to deactivate the TO, please update "Expiration Date" accordingly and save changes. Once expiration date passes, TO will be marked as deactivated automatically.',
  };
};

const PTMDefaultText =
  'HiQo Solutions will provide software development services as defined in Section 2.1a of the MSA. The customer will task HiQo. All hours will be logged and are subject to approval by the customer. HiQo will present all hours once a week for approval. If the customer does not disapprove any of the hours within five business days they shall be deemed approved.';
const ODCDefaultText =
  'HiQo will provide the following team in accordance with the rules of an ODC as described in the MSA referenced herein.';
const TTMDefaultText = 'HiQo Solutions will provide the following team:';

export const getDefaultText = ({ projectType, description }) => {
  switch (projectType) {
    case 'PTM':
      return description ?
        `${description} \n${PTMDefaultText}` :
        PTMDefaultText;
    case 'ODC':
      return description ?
        `${description} \n${ODCDefaultText}` :
        ODCDefaultText;
    case 'TTM':
      return description ?
        `${description} \n${TTMDefaultText}` :
        TTMDefaultText;
    default:
      return description;
  }
};

export const onSubmitUploadedFile = ({
  values,
  entityId: projectId,
  effectiveDate,
  file,
  entityName,
  onSubmit,
}) => {
  const { documentTitle, documentStatus } = values;

  onSubmit({
    projectId,
    fileName: Date.now(),
    title: documentTitle,
    effectiveDate,
    isSigned: documentStatus,
    file,
    entityName,
  });
};

export const getOvertimeRate = (rate, overtimePerc) => (rate * (100 + overtimePerc)) / 100;

export const isUnitLocked = (
  isSigned,
  isTaskOrderActive,
  isManuallyDeactivated,
  isExpired,
  isCEO,
  isCSO
) => {
  if (isExpired) {
    return true;
  }
  if (isTaskOrderActive) {
    return true;
  }
  if (isManuallyDeactivated && isCEO) {
    return false;
  }
  if (isSigned) {
    return true;
  }
  return !(!isTaskOrderActive && (isCEO || isCSO));
};
