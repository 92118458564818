import {
  appStorage,
} from 'core/storage';
import update from 'immutability-helper';
import {
  createReducer,
} from 'redux-create-reducer';

import {
  clientsActionsTypes,
} from './actions';

import {
  ENTITY_NAME,
  STORE_KEYS,
} from './constants';

const initialState = {
  errors: {},
  entityName: ENTITY_NAME,
  isFiltering: false,
  isFetching: false,
  isFetchingDetails: false,
  isFetchingFiles: false,
  isFormSubmitted: false,
  requestsCounter: 0,
  clientsData: [],
  clientDetails: {},
  defaultMsaTemplate: {},
  taskOrderToCopyId: null,
  favoritesClients: appStorage.getFavoritesClients() || {},
  msaDate: null,
  orderRules: {
    clientsList: {
      orderBy: 'name',
      defaultOrderBy: 'name',
      isReversed: false,
      isSeparatedByFavorites: true,
      isOffSeparatingManually: false,
    },
    taskOrders: {
      orderBy: 'TO #',
      isReversed: false,
    },
    otherDocuments: {
      orderBy: 'Upload Date',
      isReversed: false,
    },
    MSA: {
      orderBy: 'Upload Date',
      isReversed: false,
    },
  },

  filters: {
    [STORE_KEYS.START_FROM]: {
      type: 'startFrom',
      selected: '',
      isActive: false,
      storeKey: STORE_KEYS.START_FROM,
    },
    [STORE_KEYS.IS_FAVORED]: {
      type: 'toggle',
      isActive: false,
    },
    [STORE_KEYS.NAME]: {
      type: 'search',
      selected: '',
      isActive: false,
    },
    [STORE_KEYS.COUNTRY]: {
      type: 'select',
      selected: [],
      isActive: false,
    },
    [STORE_KEYS.BILLING_AGENT_NAME]: {
      type: 'select',
      selected: [],
      isActive: false,
    },
    [STORE_KEYS.RATE_CARD_NAME]: {
      type: 'select',
      selected: [],
      isActive: false,
    },
    [STORE_KEYS.USER_FULL_NAME]: {
      type: 'select',
      selected: [],
      isActive: false,
    },
    [STORE_KEYS.STATUS]: {
      type: 'select',
      selected: [],
      isActive: false,
    },
    [STORE_KEYS.IS_PLATINUM_RATES]: {
      type: 'select',
      selected: [],
      isActive: false,
    },
    [STORE_KEYS.IN_ARREARS]: {
      type: 'select',
      selected: [],
      isActive: false,
    },
  },
};

export default createReducer(initialState, {
  [clientsActionsTypes.SELECT_TASK_ORDER_TO_COPY](state, {
    payload: { taskOrderId },
  }) {
    return update(state, {
      taskOrderToCopyId: { $set: taskOrderId },
    });
  },

  [clientsActionsTypes.GET_CLIENTS_LIST](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [clientsActionsTypes.GET_CLIENTS_LIST_SUCCESS](state, {
    payload: {
      clientsList,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      clientsData: {
        $set: clientsList,
      },
      errors: {
        $unset: ['getClientsListError'],
      },
    });
  },

  [clientsActionsTypes.GET_CLIENTS_LIST_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [clientsActionsTypes.GET_CLIENT_DETAILS](state, {
    meta: {
      withReset,
    },
  }) {
    const stateUpdates = withReset ? {
      clientDetails: {
        $set: {},
      },
      taskOrderToCopyId: { $set: null },
      isFetchingDetails: {
        $set: true,
      },
    } : {
      isFetchingDetails: {
        $set: true,
      },
    };

    return update(state, stateUpdates);
  },

  [clientsActionsTypes.GET_CLIENT_DETAILS_SUCCESS](state, {
    payload: {
      clientDetails,
    },
  }) {
    return update(state, {
      isFetchingDetails: {
        $set: false,
      },
      isFetchingFiles: {
        $set: false,
      },
      clientDetails: {
        $set: {
          ...clientDetails,
          devcenters:
            clientDetails.devcenters.filter(({ active }) => active),
          allDevcenters:
            clientDetails.devcenters,
        },
      },
      errors: {
        $unset: ['getClientDetailError'],
      },
    });
  },

  [clientsActionsTypes.GET_CLIENT_DETAILS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      isFetchingDetails: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [clientsActionsTypes.CREATE_CLIENT](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [clientsActionsTypes.CREATE_CLIENT_SUCCESS](state, {
    payload: {
      clientDetails,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      clientDetails: {
        $merge: clientDetails,
      },
      errors: {
        $unset: ['createClientError'],
      },
    });
  },

  [clientsActionsTypes.CREATE_CLIENT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [clientsActionsTypes.UPDATE_CLIENT_DETAILS](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [clientsActionsTypes.UPDATE_CLIENT_DETAILS_SUCCESS](state, {
    payload: {
      clientId: id,
      clientDetails: data,
    },
  }) {
    const {
      clientsData,
      clientDetails,
    } = state;

    const clientIndex = clientsData.findIndex(({
      clientId,
    }) => clientId === id);

    if (clientIndex !== -1) {
      return update(state, {
        errors: {
          $unset: ['updateClientDetailsError'],
        },
        isFormSubmitted: {
          $set: false,
        },
        clientsData: {
          [clientIndex]: {
            $merge: data,
          },
        },
        clientDetails: {
          $merge: data,
        },
      });
    }

    if (+clientDetails.clientId === +id) {
      return update(state, {
        isFormSubmitted: {
          $set: false,
        },
        clientDetails: {
          $merge: data,
        },
        errors: {
          $unset: ['updateClientDetailsError'],
        },
      });
    }

    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $unset: ['updateClientDetailsError'],
      },
    });
  },

  [clientsActionsTypes.UPDATE_CLIENT_DETAILS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [clientsActionsTypes.DELETE_DOCUMENT](state) {
    return update(state, {
      isFormSubmitted: {
        $set: true,
      },
    });
  },

  [clientsActionsTypes.DELETE_DOCUMENT_SUCCESS](state, {
    payload: {
      fileId: id,
    },
  }) {
    const {
      clientDetails,
    } = state;
    const fileIndex = clientDetails.filesMsa.findIndex(({
      fileId,
    }) => fileId === id);
    const numberOfFiles = clientDetails.files;

    if (fileIndex !== -1) {
      return update(state, {
        isFormSubmitted: {
          $set: false,
        },
        errors: {
          $unset: ['deleteDocumentError'],
        },
        clientDetails: {
          files: {
            $set: numberOfFiles - 1,
          }, // TODO: rename to numberOfFiles
          filesMsa: {
            $apply: () => clientDetails.filesMsa.filter(({
              fileId,
            }) => fileId !== id),
          },
        },
      });
    }
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $unset: ['deleteDocumentError'],
      },
      clientDetails: {
        files: {
          $set: numberOfFiles - 1,
        }, // TODO: rename to numberOfFiles
        filesOthers: {
          $apply: () => clientDetails.filesOthers.filter(({
            fileId,
          }) => fileId !== id),
        },
      },
    });
  },

  [clientsActionsTypes.DELETE_DOCUMENT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [clientsActionsTypes.CHANGE_FILTER](state) {
    return update(state, {
      isFiltering: {
        $set: true,
      },
    });
  },

  [clientsActionsTypes.UPDATE_FAVORITES](state, {
    payload,
  }) {
    return update(state, {
      favoritesClients: {
        $set: payload,
      },
    });
  },

  [clientsActionsTypes.CHANGE_FILTER_SUCCESS](state, {
    payload: {
      storeKey,
      config,
    },
  }) {
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        [storeKey]: {
          $merge: config,
        },
      },
    });
  },

  [clientsActionsTypes.RESET_FILTERS](state) {
    return update(state, {
      isFiltering: {
        $set: false,
      },
      filters: {
        $merge: initialState.filters,
      },
    });
  },

  [clientsActionsTypes.SET_ORDER_RULES](state, {
    payload: {
      tableName,
      orderRules,
    },
  }) {
    return update(state, {
      orderRules: {
        [tableName]: {
          $set: orderRules,
        },
      },
    });
  },

  [clientsActionsTypes.GET_UPLOADED_FILE](state) {
    const {
      requestsCounter,
    } = state;

    return update(state, {
      isFetchingFiles: {
        $set: true,
      },
      requestsCounter: {
        $set: requestsCounter + 1,
      },
    });
  },

  [clientsActionsTypes.GET_UPLOADED_FILE_SUCCESS](state, {
    payload: {
      file,
    },
  }) {
    const {
      clientDetails,
    } = state;
    const filesMsa = [];
    const filesOthers = [];

    const {
      fileType,
      isSigned: isFileSigned,
    } = file;

    const {
      isSignedMsaUploaded,
    } = clientDetails;

    const numberOfFiles = clientDetails.files;

    if (fileType && fileType === 'MSA') {
      filesMsa.push(file);
    } else {
      filesOthers.push(file);
    }

    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      errors: {
        $unset: ['getUploadedFileError'],
      },
      requestsCounter: {
        $set: 0,
      },
      clientDetails: {
        files: {
          $set: numberOfFiles + 1,
        },
        filesMsa: {
          $push: filesMsa,
        },
        filesOthers: {
          $push: filesOthers,
        },
        isSignedMsaUploaded: {
          $set: isSignedMsaUploaded || (isFileSigned && fileType === 'MSA'),
        },
      },
    });
  },

  [clientsActionsTypes.GET_UPLOADED_FILE_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      requestsCounter: {
        $set: 0,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [clientsActionsTypes.GENERATE_DOCUMENT](state) {
    return update(state, {
      isFetchingFiles: {
        $set: true,
      },
      msaDate: {
        $set: null,
      },
    });
  },

  [clientsActionsTypes.GENERATE_DOCUMENT_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['generateDocumentError'],
      },
    });
  },

  [clientsActionsTypes.GENERATE_DOCUMENT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetchingFiles: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [clientsActionsTypes.SIGN_DOCUMENT_SUCCESS](state, {
    payload: {
      fileId: id,
    },
  }) {
    const {
      clientDetails,
    } = state;
    const fileIndex = clientDetails.filesMsa.findIndex(({
      fileId,
    }) => fileId === id);

    if (fileIndex !== -1) {
      return update(state, {
        errors: {
          $unset: ['signDocumentError'],
        },
        isFormSubmitted: {
          $set: false,
        },
        clientDetails: {
          filesMsa: {
            [fileIndex]: {
              $merge: {
                isRequestToSignSent: true,
              },
            },
          },
        },
      });
    }

    // TODO: if we can sign other files, add clause for that

    return state;
  },

  [clientsActionsTypes.SIGN_DOCUMENT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFormSubmitted: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
    });
  },

  [clientsActionsTypes.SET_MSA_DATE](state, {
    payload: {
      msaDate,
    },
  }) {
    return update(state, {
      msaDate: {
        $set: msaDate,
      },
    });
  },
  [clientsActionsTypes.CLEAR_ERRORS](state) {
    return update(state, {
      errors: {
        $unset: ['updateClientDetailsError'],
      },
    });
  },
  [clientsActionsTypes.GET_MSA_BY_CLIENT_ID](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
      errors: {
        $set: [],
      },
    });
  },
  [clientsActionsTypes.GET_MSA_BY_CLIENT_ID_SUCCESS](state, { payload }) {
    return update(state, {
      clientDetails: {
        msa: {
          $set: payload.msa,
        },
      },
      errors: {
        $set: [],
      },
      isFetching: {
        $set: false,
      },
    });
  },
  [clientsActionsTypes.GET_MSA_BY_CLIENT_ID_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      errors: {
        $merge: error,
      },
      isFetching: {
        $set: false,
      },
    });
  },
});
