import {
  colorPrimary,
  colorSecondaryGray,
  colorSecondaryGrayLight,
} from 'assets/styles/variables';
import { selectUserGroup } from 'core/auth/selectors';
import { generateInitialTeamAndMap, generateTeamAndMap, parseReview, optionStyles } from 'core/employees-reviews/utils';
import { employeesReviewsManagementModelGetter } from 'layouts/employees-reviews-management/model';
import { employeesReviewsReportModelGetter } from 'layouts/employees-reviews-report/model';
import { get, isEmpty, set } from 'lodash';
import { createSelector } from 'reselect';
import { parseDetails } from 'utils/helpers/models';
import {
  stringCompareFunction,
  numberCompareFunction,
} from 'utils/helpers/sorting';

import { ADMIN_STAFF_ID, CUSTOMER_ID } from './constants';

const isEDUCategory = (id) => `${id}`.toLocaleLowerCase().includes('10184');

const selectLocalState = ({ employeesReviews = {} }) => employeesReviews;

export const selectErrors = createSelector(
  selectLocalState,
  (state) => state.errors
);
export const selectOrderRules = createSelector(
  selectLocalState,
  (state) => state.orderRules
);
export const selectEntityName = createSelector(
  selectLocalState,
  (state) => state.entityName
);
export const selectSelectedDate = createSelector(
  selectLocalState,
  (state) => state.selectedDate
);
export const selectIsFormSubmitted = createSelector(
  selectLocalState,
  (state) => state.isFormSubmitted
);
export const selectSelectedEmployee = createSelector(
  selectLocalState,
  (state) => state.selectedEmployee
);
export const selectIsFetchingReviews = createSelector(
  selectLocalState,
  (state) => state.isFetchingReviews
);
export const selectReviewsReportsCommonData = createSelector(
  selectLocalState,
  (state) => state.reviewReportCommonData
);
export const selectIsFetchingCommonReviewsData = createSelector(
  selectLocalState,
  (state) => state.isFetchingCommonReviewsData
);
export const selectControls = createSelector(selectLocalState, (state) => state.controls);
export const selectIsActiveControls = createSelector(selectLocalState, (state) => Object.values(state.controls).some(Boolean));
export const selectIsFetching = createSelector(
  selectIsFetchingReviews,
  selectIsFetchingCommonReviewsData,
  (isFetchingReviews, isFetchingCommonReviewsData) => isFetchingReviews || isFetchingCommonReviewsData
);

const selectReviewsList = createSelector(
  selectLocalState,
  (state) => state.reviews,
);

const selectNotificationsLog = createSelector(
  selectLocalState,
  ({ notificationsLog }) => notificationsLog.reduce(
    (acc, { projectId, notificationDatetime }) => ({
      ...acc,
      [projectId]: notificationDatetime,
    }),
    {}
  )
);
const selectProjectsList = createSelector(
  selectLocalState,
  (state) => state.projectsList
);
const selectOtherReviews = createSelector(
  selectLocalState,
  (state) => state.otherReviews,
);
const selectEmployeesList = createSelector(
  selectLocalState,
  (state) => state.employeesList
);
const selectEmployeeReviews = createSelector(
  selectLocalState,
  (state) => state.employeeReviews
);
const selectAdditionalColumns = createSelector(
  selectLocalState,
  (state) => state.additionalColumns
);

const selectSortedProjectsData = createSelector(
  selectProjectsList,
  (projects) => projects
    .sort((a, b) => {
      const isLeftEDU = isEDUCategory(a.value);
      const isRightEDU = isEDUCategory(b.value);
      if (isLeftEDU || isRightEDU) {
        return isLeftEDU ? -1 : 1;
      }

      return stringCompareFunction(a.label, b.label);
    })
    .reduce(
      ({ map, list }, { value, label }) => ({
        map: {
          ...map,
          [value]: {
            value,
            label,
          },
        },
        list: [
          ...list,
          {
            value,
            label,
            styles: isEDUCategory(value) ? optionStyles : null,
          },
        ],
      }),
      {
        map: {
          0: {
            value: 0,
            label: 'Admin Staff',
          },
        },
        list: [
          {
            value: 0,
            label: 'Admin Staff',
            styles: optionStyles,
          },
        ],
      }
    )
);

const selectSortedEmployeesList = createSelector(
  selectEmployeesList,
  (employeesList) => {
    const map = {
      [CUSTOMER_ID]: {
        label: 'Customer',
        value: CUSTOMER_ID,
      },
      [ADMIN_STAFF_ID]: {
        label: 'Admin Staff',
        value: ADMIN_STAFF_ID,
      },
    };
    const list = [];

    employeesList.forEach(({ devstaffId, fullname, name }) => {
      const employee = { value: devstaffId, label: fullname || name };
      map[devstaffId] = employee;
      list.push(employee);
    });

    list.sort((a, b) => stringCompareFunction(a.label, b.label));
    return {
      map,
      list,
    };
  }
);

export const selectGroupedEmployeeByCategory = createSelector(selectReviewsList, (reviews) => {
  const groupedEmployeeByCategory = new Map();

  reviews.forEach((review) => {
    const key = review.jiraProjectName || review.internalCategoryName;

    const employee = {
      value: review.devstaffId,
      label: review.devstaffName,
    };

    if (groupedEmployeeByCategory.has(key)) {
      groupedEmployeeByCategory.get(key).push(employee);
    } else {
      groupedEmployeeByCategory.set(key, [employee]);
    }
  });

  return groupedEmployeeByCategory;
});

export const selectGroupedReviews = createSelector(
  selectReviewsList,
  selectOtherReviews,
  selectGroupedEmployeeByCategory,
  (reviews, otherReviews, groupedEmployeeByCategory) => {
    const groupedReviewsList = [];
    const entity = {};

    reviews.forEach(({
      role,
      defaultRole,
      reviews: storedReviews,
      ...otherParams
    }) => {
      const {
        jiraProjectName,
        jiraProject,
        plName,
        plDevstaffId,
        ...rest
      } = otherParams;
      const isPl = get(rest, 'isPl', false);
      const internalCategoryName = get(rest, 'internalCategoryName');
      const internalCategoryId = get(rest, 'internalCategoryId');
      const isOldInterface = get(rest, 'isOldInterface', true);
      const projectName = isOldInterface ? jiraProjectName : internalCategoryName;
      const projectId = isOldInterface ? jiraProject : internalCategoryId;
      const devstaffId = get(rest, 'devstaffId');
      const isReviewRequired = get(rest, 'isReviewRequired');
      const isBlocked = get(rest, 'isBlocked');
      const reviewsRecords = storedReviews.map((item) => parseReview(item));

      const index = get(entity, projectId, groupedReviewsList.length);
      entity[projectId] = index;

      const projectMap = get(groupedReviewsList, `[${index}.projectMap]`, {});
      projectMap[devstaffId] = {
        ...rest,
        role: role || defaultRole,
        defaultRole,
      };

      const records = get(groupedReviewsList, `[${index}].records`, []);
      const updatedListOfDevstaffIdsThatHasNoReviews = [...get(
        groupedReviewsList,
        `[${index}].listOfDevstaffIdsThatHasNoReviews`,
        []
      )];

      const hasReviews = !!reviewsRecords.length;
      const updatedRecords = [
        ...records,
        {
          jiraProjectName: projectName,
          defaultRole,
          reviews: reviewsRecords,
          role: role || defaultRole,
          initialJiraProject: projectId,
          ...rest,
        },
      ];

      if (!hasReviews && isReviewRequired && !isBlocked) {
        updatedListOfDevstaffIdsThatHasNoReviews.push({ devstaffId, isPl });
      }

      groupedReviewsList[index] = {
        excludedDevstaffs: [
          devstaffId,
          plDevstaffId,
        ],
        ...generateTeamAndMap(groupedReviewsList, groupedEmployeeByCategory, {
          plDevstaffId,
          plName,
          projectName,
        }, index),
        projectMap,
        jiraProject: projectId,
        jiraProjectName: projectName,
        records: updatedRecords,
        listOfDevstaffIdsThatHasNoReviews:
            updatedListOfDevstaffIdsThatHasNoReviews,
      };
    });

    groupedReviewsList.push({
      excludedDevstaffs: [],
      ...generateInitialTeamAndMap(),
      isOther: true,
      jiraProject: 0,
      jiraProjectName: 'Other',
      records: otherReviews.map(({ reviews: storedReviews, jiraProject, loggedHrs, internalCategoryId, ...record }) => ({
        reviews: storedReviews.map(parseReview),
        isOutOfProject: true,
        initialJiraProject: jiraProject || internalCategoryId,
        jiraProject: jiraProject || internalCategoryId,
        loggedHrs,
        ...record,
      })),
    });

    groupedReviewsList.forEach((item) => item.records.forEach((record) => {
      if (isEmpty(record.reviews)) {
        const obj = {
          isAdminStaffReview: null,
          isCustomerReview: null,
          rating: null,
          recordId: null,
          review: '',
          reviewby: '',
          reviewbyId: null,
        };
        record.reviews.push(obj);
      }
    }));

    return { groupedReviewsList };
  }
);

export const selectReviewsFormFilterOptions = createSelector(
  selectGroupedReviews,
  ({ groupedReviewsList }) => groupedReviewsList.map(({ jiraProject, jiraProjectName }) => ({
    value: jiraProject,
    label: jiraProjectName,
  }))
);

export const selectReviewsManagementModel = createSelector(
  selectGroupedReviews,
  selectSortedEmployeesList,
  selectSortedProjectsData,
  selectNotificationsLog,
  selectUserGroup,
  selectControls,
  (
    data,
    employeesList,
    { list: projectsList, map: projectsMap },
    notificationsLog,
    userGroup,
    controls,
  ) => employeesReviewsManagementModelGetter({
    data: get(data, 'groupedReviewsList', []),
    employeesList,
    projectsList,
    projectsMap,
    notificationsLog,
    userGroup,
    controls,
  })
);

export const selectEmployeesListForReport = createSelector(
  selectEmployeesList,
  (employeesList) => employeesList
    .map(({
      fullname,
      name,
      devstaffId,
      isNew,
      isIntern,
      isOnLeave,
      isDismissed,
    }) => {
      const getOptionStatus = () => {
        if (isNew) {
          return {
            status: '"New"',
            statusColor: colorPrimary,
          };
        }
        if (isDismissed) {
          return {
            status: '"Dismissed"',
            statusColor: colorSecondaryGrayLight,
          };
        }
        if (isIntern) {
          return {
            status: '"Intern"',
            statusColor: colorSecondaryGray,
            isActive: true,
          };
        }
        if (isOnLeave) {
          return {
            status: '"On leave"',
            statusColor: colorSecondaryGrayLight,
          };
        }
        return {
          status: '"Employed"',
          statusColor: colorSecondaryGray,
          isActive: true,
        };
      };

      const { status, statusColor, isActive } = getOptionStatus();

      return {
        value: devstaffId,
        label: fullname || name,
        isActive,
        styles: {
          display: 'flex',
          justifyContent: 'space-between',
          ':after': {
            content: status,
            display: 'inline-block',
            marginLeft: '1.6rem',
            fontSize: '1.1rem',
            linHeight: '1.6rem',
            textAlign: 'right',
            color: statusColor,
          },
          '&:hover': {
            color: colorPrimary,
          },
        },
      };
    })
    .sort((a, b) => stringCompareFunction(a.label, b.label))
);

export const selectFilters = createSelector(
  selectLocalState,
  (state) => state.filters
);

export const selectIsFiltersActive = createSelector(
  selectReviewsReportsCommonData,
  selectFilters,
  ({ lastReviewDate, firstReviewDate }, { dateFrom, dateTo }) => {
    if (!lastReviewDate || !firstReviewDate || !dateFrom || !dateTo) {
      return false;
    }
    return !lastReviewDate.isSame(dateTo) || !firstReviewDate.isSame(dateFrom);
  }
);

const selectGroupedEmployeesReviews = createSelector(
  selectEmployeeReviews,
  selectFilters,
  (employeeReviews, filters) => {
    const groupedReviews = {};

    employeeReviews.forEach((currentRow) => {
      const { year, week } = currentRow;
      const hasPassed = (() => {
        const { dateFrom, dateTo } = filters;
        const fromYear = dateFrom.year();
        const fromWeek = dateFrom.week();
        const toYear = dateTo.year();
        const toWeek = dateTo.week();
        const passFromDate =
          fromYear === year ? week >= fromWeek : year >= fromYear;
        const passToDate = toYear === year ? week <= toWeek : year <= toYear;

        return passFromDate && passToDate;
      })();

      if (hasPassed) {
        const rows = get(groupedReviews, `[${year}].rows`, []);
        rows.push(currentRow);
        set(groupedReviews, `[${year}].rows`, rows);
      }
    });

    return groupedReviews;
  }
);

export const selectReviewsReportModel = createSelector(
  selectAdditionalColumns,
  selectSelectedEmployee,
  selectGroupedEmployeesReviews,
  selectSortedEmployeesList,
  selectFilters,
  (
    additionalColumns,
    selectedEmployee,
    groupedEmployeesReviews,
    employeesData,
    { dateFrom, dateTo }
  ) => employeesReviewsReportModelGetter({
    additionalColumns,
    selectedEmployee,
    groupedEmployeesReviews: Object.entries(groupedEmployeesReviews).sort(([a], [b]) => numberCompareFunction(a, b, true)),
    employeesData,
    dateFrom,
    dateTo,
  })
);

export const selectedReviewsManagementData = createSelector(
  selectGroupedReviews,
  selectReviewsManagementModel,
  ({ groupedReviewsList }, model) => parseDetails(groupedReviewsList, model)
);

export const selectedReviewsReportData = createSelector(
  selectGroupedEmployeesReviews,
  selectReviewsReportModel,
  selectOrderRules,
  (data, model, orderRules) => parseDetails(data, model, orderRules)
);
