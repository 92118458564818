import {
  colorSecondaryText,
} from 'assets/styles/variables';

import { getAdditionalControlsCommonCssGrid, generateEndOfProbationByValidFrom } from 'layouts/employee-details/model/utils/workbook';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import {
  string,
  number,
} from 'yup';

import {
  compareCalendarDates,
  getCalendarValidationMessage,
  descriptionInactiveCSS,
  errorBoxCssRules,
  fieldsDataBySeniority,
  hrsDayItems,
  internalSeniorityItems,
  plLevelByIdForFormRepresentation,
  plLevelField,
  seniorityItems,
  workBookFormTextInputCss,
  workBookSelectStyles,
  ACTION_BUTTON_TEXTS,
} from './utils/workbook';

moment.tz.setDefault('UTC');

export const generateControlsConfigGetter = () => ({
  cssRules: getAdditionalControlsCommonCssGrid(true),
  content: [
    {
      type: 'description',
      data: 'Not Available',
      isMultiline: true,
      cssRules: descriptionInactiveCSS,
    },
    {
      type: 'description',
      data: ACTION_BUTTON_TEXTS.NOT_AVAILABLE,
      isMultiline: true,
      cssRules: descriptionInactiveCSS,
    },
  ],
});

export const createWorkBookFormGetter = ({
  partialGridTemplateColumnsConfig,
  titlesFromDevstaffCompensation,
  hasPermissionsToUpdate,
  dismissalDate,
  isDismissed,
  devstaffId,
  rehireDate,
  formStyles,
  devcentersListForSelect,
  dateHired,
  dateLast,
  fullname,
  isCEO,
  record,
  isMoreThanOneRecord,
  internshipStartDate,
  isSalaryHidden,
  isArchivedDevCenter,
}) => {
  const {
    remarks,
    seniority,
    recordId,
    probation,
    endOfProbation,
    validFrom,
    seniorityInt,
    hrsDay,
    title,
    approvedDd,
    plLevel,
    salUsd,
    salPln,
    salRur,
    devcenter,
  } = record;

  return {
    withErrorBox: true,
    recordId: `${recordId}/${dateHired || ''}`,
    onSubmitAction: devstaffId ? 'createWorkbookRecord' : 'createEmployee',
    stylesTemplate: {
      cssRules: `
      grid-area: record;
      grid-template-columns: 1fr;
      grid-row-gap: 0;
      padding-bottom: 2.4rem;
    `,
      errorBoxCssRules,
      formWrapperStyles: `
      grid-area: record;
      margin: 0.4rem 0 0;
      padding: 0;
      background-color: #fbfbfb;
    `,
      formStyles,
    },
    mainControlsConfig: {
      cssRules: `
      grid-template-columns: 8.8rem;
      grid-area: controls;
      grid-template-rows: repeat(2, 3.2rem);
      grid-row-gap: 1.6rem;
      margin: 0 auto;
      padding-top: 2rem;
      align-content: flex-start;
      font-size: 1.2rem;
    `,
      onReject: ({
        actions,
        handleReset,
        hasFormChanges,
      }) => {
        if (hasFormChanges) {
          return handleReset();
        }

        return actions.removeWorkBookTemplateRecord();
      },
      /* return object according current form state, used in FormMainControls component */
      getResetButtonConfig: ({
        hasFormChanges,
      }) => ({
        isResetVisible: hasFormChanges || isMoreThanOneRecord,
        rejectActionTitle: hasFormChanges ? ACTION_BUTTON_TEXTS.CANCEL : ACTION_BUTTON_TEXTS.DELETE,
      }),
    },
    additionalControlsConfig: generateControlsConfigGetter(),
    content: [
      {
        stylesTemplate: {
          marginTop: '0.3rem',
          cssRules: `
        grid-template-columns: ${partialGridTemplateColumnsConfig};
        grid-column-gap: 0.8rem;
        padding: 0;
      `,
        },
        formData: [{
          type: 'viewBox',
          getIsUnitLocked: () => true,
          isHidden: !fullname || !devstaffId,
          value: {
            value: fullname,
            path: `/delivery/employee/${devstaffId}/work-book`,
          },
          cssRules: `
        height: 3.6rem;
        margin: 0.9rem 0 0 1.6rem;
        padding: 1.4rem 0.4rem 0;
        font-size: 1.3rem;
        white-space: nowrap;
        overflow: hidden;
        && {
          .viewbox__viewbox-link {
            display: inline-block;
          }
        }
      `,
        },
        {
          type: 'calendar',
          name: 'validFrom',
          label: 'Valid from',
          value: devstaffId ? validFrom : dateHired,
          outputFormatting: ({ date }) => (
            moment.isMoment(date) ?
              date.format('YYYY-MM-DD') :
              date
          ),
          popperProps: {
            placement: 'bottom-start',
          },
          validationRules: {
            schemaGetter: () => string()
              .nullable()
              .test(
                'The selected date can\'t be earlier than "Reonboarding Date".',
                'The selected date can\'t be earlier than "Reonboarding Date".',
                (date) => !moment(rehireDate).isAfter(moment(date))
              )
              .test(
                'isCorrectDate',
                getCalendarValidationMessage({
                  isDismissed,
                  dateHired,
                  dateLast,
                }),
                (date) => compareCalendarDates(date, {
                  isDismissed,
                  dateHired,
                  dismissalDate,
                  internshipStartDate,
                  dateLast,
                }),
              ).required('Required field'),
          },
          cssRules: `
        align-items: center !important;
        max-height: unset;
        font-size: 1.2rem;
        letter-spacing: -0.0025rem;
        color: ${colorSecondaryText};
        && {
          ${fullname && devstaffId ? '' : `
            width: calc(100% - 1.6rem);
            min-width: auto;
            margin-left: 1.6rem;
          `}
        }
      `,
        },
        {
          type: 'select',
          name: 'devcenter',
          label: 'Dev center',
          value: {
            selected: {
              value: isArchivedDevCenter ? null : devcenter,
            },
            items: devcentersListForSelect,
          },
          cssRules: workBookSelectStyles,
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().nullable().required('Required field'),
          },
        },
        {
          type: 'select',
          name: 'title',
          label: 'Title',
          isCreatable: true,
          value: {
            selected: {
              value: title,
            },
            items: titlesFromDevstaffCompensation || [
              {
                value: title,
                label: title,
              },
            ],
          },
          validationRules: {
            isRequired: true,
            maxLength: 80,
            schemaGetter: () => string()
              .max(80, 'Title max length is 80')
              .required('Required field'),
          },
          cssRules: `
        ${workBookSelectStyles}
        & .select-item__single-value {
          overflow: hidden;
        }
        && .select-item__menu {
          width: 156%;
        }
      `,
        },
        {
          type: 'selectNew',
          name: 'seniority',
          label: 'Seniority',
          value: {
            selected: get(fieldsDataBySeniority, `${seniority}.selected`, dateHired ? {
              value: '',
              label: '--',
            } : {
              value: 'Intern',
              label: 'Intern',
            }),
            items: dateHired ? seniorityItems : [],
          },
          cssRules: workBookSelectStyles,
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().nullable().required('Required field'),
          },
        },
        {
          type: 'selectNew',
          name: 'seniorityInt',
          label: 'Internal Seniority',
          value: {
            selected: {
              value: seniorityInt,
              label: '--',
            },
            items: get(internalSeniorityItems, `${seniority}`, []),
          },
          onFormChange: ({
            errors,
            values: {
              seniority: storedSeniority,
            },
            setFieldError,
          }) => {
            const isIntern = storedSeniority === 'Intern';

            if (isIntern && get(errors, 'seniorityInt')) {
              toast.dismiss('seniorityInt');
              setFieldError('seniorityInt', null);
            }

            if (isIntern && get(errors, 'salUsd')) {
              toast.dismiss('salUsd');
              setFieldError('salUsd', null);
            }
            return {};
          },
          parentField: {
            name: ['seniority'],
            strictDependence: true,
            valueGetter: ({
              seniority: storedSeniority,
              seniorityInt: storedSeniorityInt,
            }) => {
              const items = get(internalSeniorityItems, `${storedSeniority}`, []);

              const selected = items.find(({
                value,
              }) => value === storedSeniorityInt) || {
                value: '',
                label: '--',
              };

              return {
                selected,
                items,
              };
            },
          },
          cssRules: workBookSelectStyles,
          validationRules: {
            isRequired: true,
            schemaGetter: () => string()
              .nullable()
              .when('seniority', (storedSeniority, schema) => storedSeniority === 'Intern' ? schema : schema.required('Required field')),
          },
        },
        {
          type: 'select',
          name: 'plLevel',
          value: {
            selected: {
              value: plLevelByIdForFormRepresentation[plLevel] || '-2',
              label: '--',
            },
            items: plLevelField.items,
          },
          onFormChange: ({
            errors,
            values: {
              seniority: storedSeniority,
              plLevel: storedPlLevel,
            },
            setFieldValue,
          }) => {
            const isIntern = storedSeniority === 'Intern';

            if (isIntern && get(errors, 'plLevel')) {
              toast.dismiss('plLevel');
            }

            if (isIntern && storedPlLevel !== '-2') {
              setFieldValue('plLevel', '-2');
            }
            return {
              isLocked: isIntern,
            };
          },
          cssRules: workBookSelectStyles,
          validationRules: {
            isRequired: true,
          },
        },
        {
          type: 'select',
          name: 'hrsDay',
          label: 'Hrs/day',
          value: {
            selected: {
              value: hrsDay,
              label: hrsDay,
            },
            items: hrsDayItems,
          },
          validationRules: {
            isRequired: true,
            schemaGetter: () => number().required('Required field'),
          },
          cssRules: workBookSelectStyles,
        },
        {
          type: 'checkbox',
          name: 'probation',
          value: !!probation,
          cssRules: 'justify-content: flex-end; padding: 0.3rem; font-size: 1.6rem;',
          onFormChange: ({
            values: {
              seniority: storedSeniority,
              probation: storedProbation,
            },
            setFieldValue,
          }) => {
            const isIntern = storedSeniority === 'Intern';

            if (isIntern && storedProbation !== false) {
              setFieldValue('probation', false);
            }
            return {
              isLocked: isIntern,
            };
          },
          validationRules: {},
        },
        {
          type: 'calendar',
          name: 'endOfProbation',
          label: 'End of Probation',
          value: probation ? endOfProbation : null,
          outputFormatting: ({ date }) => (
            moment.isMoment(date) ?
              date.format('YYYY-MM-DD') :
              date
          ),
          popperProps: {
            placement: 'bottom-start',
          },
          getIsUnitLocked: () => !hasPermissionsToUpdate,
          onFormChange: ({ errors, values, setFieldError }) => {
            if (!values.probation && get(errors, 'endOfProbation')) {
              toast.dismiss('endOfProbation');
              setFieldError('endOfProbation', null);
            }
            return {
              formValue: values.probation ? values.endOfProbation : null,
              isLocked: !values.probation,
            };
          },
          parentField: {
            name: ['validFrom', 'probation'],
            valueGetter: ({ validFrom: storedValidFrom, probation: storedProbation }) => generateEndOfProbationByValidFrom(storedProbation, storedValidFrom),
          },
          validationRules: {
            isRequired: false,
            schemaGetter: () => string()
              .nullable()
              .test(
                'validFrom',
                'The selected date must be equal or later than Valid from date',
                function test(storedEndOfProbation) {
                  const validFromDate = moment(this.parent.validFrom);
                  const storedEndOfProbationDate = moment(storedEndOfProbation);
                  const storedProbation = this.parent.probation;
                  if (!storedProbation) {
                    return true;
                  }
                  return storedEndOfProbationDate.isSameOrAfter(validFromDate, 'day');
                },
              ),
          },
          cssRules: `
            align-items: center;
            max-height: unset;
            font-size: 1.2rem;
            letter-spacing: -0.0025rem;
            color: ${colorSecondaryText};
          `,
        },
        {
          type: 'text',
          name: 'salUsd',
          label: 'USD, RUR, PLN',
          isHidden: isSalaryHidden,
          value: {
            placeholder: '0',
            value: salUsd,
          },
          validationRules: {
            isRequired: true,
            isNumeric: true,
            maxValue: 99999,
            schemaGetter: hasPermissionsToUpdate && !isSalaryHidden ? () => string()
              .nullable()
              .when(
                ['salPln', 'salRur', 'seniority'],
                (storedSalPln, storedSalRur, storedSeniority, schema) => {
                  const isIntern = storedSeniority === 'Intern';

                  if (isIntern) {
                    return schema;
                  }

                  return (storedSalPln && Number(storedSalPln) !== 0) ||
                    (storedSalRur && Number(storedSalRur) !== 0) ?
                    schema :
                    schema.test('noSalaryInfo', 'Please enter valid salary value in any of the following currencies: USD, PLN, RUR.', (storedSalUsd) => (storedSalUsd && Number(storedSalUsd) !== 0));
                }
              ) : null,
          },
          cssRules: `${workBookFormTextInputCss} text-align: right;`,
          onFormChange: ({
            setFieldValue,
            errors,
            values: {
              seniority: storedSeniority,
              salUsd: storedSalUsd,
            },
          }) => {
            const isIntern = storedSeniority === 'Intern';
            const isLocked = !!(isIntern);

            if (isIntern && get(errors, 'salUsd')) {
              toast.dismiss('salUsd');
            }

            if (isIntern && Number(storedSalUsd) !== 0) {
              setFieldValue('salUsd', null);
            }
            return {
              isLocked,
            };
          },
        },
        {
          type: 'text',
          name: 'salPln',
          value: {
            placeholder: '0',
            value: salPln,
          },
          isHidden: isSalaryHidden,
          validationRules: {
            isRequired: true,
            isNumeric: true,
            maxValue: 99999,
          },
          cssRules: `${workBookFormTextInputCss} text-align: right;`,
          onFormChange: ({
            values: {
              seniority: storedSeniority,
              salPln: storedSalPln,
            },
            setFieldValue,
          }) => {
            const isIntern = storedSeniority === 'Intern';
            const isLocked = !!(isIntern);

            if (isIntern && Number(storedSalPln) !== 0) {
              setFieldValue('salPln', null);
            }
            return {
              isLocked,
            };
          },
        },
        {
          type: 'text',
          name: 'salRur',
          value: {
            placeholder: '0',
            value: salRur,
          },
          isHidden: isSalaryHidden,
          validationRules: {
            isRequired: true,
            isNumeric: true,
            maxValue: 999999,
          },
          cssRules: `${workBookFormTextInputCss} text-align: right;`,
          onFormChange: ({
            values: {
              seniority: storedSeniority,
              salRur: storedSalRur,
            },
            setFieldValue,
          }) => {
            const isIntern = storedSeniority === 'Intern';
            const isLocked = !!(isIntern);

            if (isIntern && Number(storedSalRur) !== 0) {
              setFieldValue('salRur', null);
            }
            return {
              isLocked,
            };
          },
        },
        ],
      },
      {
        stylesTemplate: {
          cssRules: `
        grid-template-columns: 1fr;
        margin-top: 0.3rem;
        padding: 0;
      `,
        },
        formData: [{
          type: 'text',
          name: 'remarks',
          placeholder: 'Remark',
          isMultiline: true,
          getIsUnitLocked: () => approvedDd && isCEO && !!remarks,
          value: remarks || '',
          validationRules: {
            isRequired: true,
            schemaGetter: () => string().trim().max(2000, 'must be at most 2000 characters').required('Required field'),
          },
          cssRules: `
        ${workBookFormTextInputCss}
        margin-left: 1.6rem;
        line-height: 1.6rem;
        & .text-input__input-field {
          padding: 0.4rem 0;
        }
      `,
        }],
      }],
  };
};
