import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { Editor } from 'components/editor';
import { Field } from 'formik';
import { get } from 'lodash';

const FormEditor = ({
  name,
  error,
  isLocked,
  onChange,
  formValue,
  cssRules,
  isTouched,
  fieldData,
  hasFormChanges,
}) => (
  <Field
    type="text"
    name={name}
    render={() => (
      <Editor
        value={formValue}
        onChange={onChange}
        placeholder={get(fieldData, 'placeholder', '')}
        cssRules={cssRules}
        isReset={hasFormChanges}
        isDisabled={isLocked}
        isError={!!(error && isTouched)}
      />
    )}
  />
);

FormEditor.propTypes = {
  isLocked: PropTypes.bool,
  validationRules: PropTypes.shape({
    isNumeric: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
  formValue: PropTypes.any,
  hasFormChanges: PropTypes.bool,
  error: PropTypes.string,
  isTouched: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  cssRules: PropTypes.string,
  fieldData: PropTypes.shape({
    isLink: PropTypes.bool,
    path: PropTypes.string,
    placeholder: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })),
    selected: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ]),
  }),
  parentField: PropTypes.shape({}),
};

FormEditor.defaultProps = {
  formValue: '',
  isLocked: false,
  validationRules: {},
  cssRules: '',
  fieldData: {
    selected: {
      value: '',
      label: '',
    },
    items: [],
    placeholder: '',
  },
  onChange: () => null,
  error: '',
  isTouched: false,
  hasFormChanges: false,
  parentField: null,
};

export default memo(FormEditor, (prevProps, currentProps) => {
  const { isTouched, formValue, error, disabled, parentFieldsData, isLocked } = prevProps;
  if (isTouched !== currentProps.isTouched ||
    formValue !== currentProps.formValue ||
    error !== currentProps.error ||
    disabled !== currentProps.disabled ||
    isLocked !== currentProps.isLocked ||
    parentFieldsData !== currentProps.parentFieldsData) {
    return false;
  }

  return true;
});
