import { push } from 'connected-react-router';
import { permissionsToProjectLeadAssignmentsTab } from 'core/auth/guaranteedAccessRoles';
import { waitForAuthorization } from 'core/auth/sagas';

import { selectDevCentersByUserGroup, selectUserGroup } from 'core/auth/selectors';
import attachmentsSagas from 'core/delivery/attachmentManagement/sagas';
import commentManagementSagas from 'core/delivery/commentManagement/sagas';
import contactDataManagementSagas from 'core/delivery/contactManagement/sagas';
import contractManagementSagas from 'core/delivery/contractManagement/sagas';
import { generateShortNameByFullName } from 'core/delivery/helpers';
import technicalMentorManagementSagas from 'core/delivery/technicalMentorManagement/sagas';
import { modalConductorActions } from 'core/modal-conductor/actions';

import { selectCurrentModal } from 'core/modal-conductor/selectors';
import { appStorage } from 'core/storage';
import { differenceWith,
  isEqual,
  get,
  has,
  isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import {
  put,
  select,
  takeLatest,
  call,
  all,
} from 'redux-saga/effects';
import { getHasPermissions } from 'utils/auth';
import { combineDevCenters } from 'utils/helpers/combineDevCenters';
import { getParsedValues } from 'utils/helpers/forms';
import { getNotifications } from 'utils/helpers/notifications';
import { dismissErrorsById } from 'utils/helpers/sagas';
import request, {
  getRequestUrl,
  executeQuery,
  executeMutation,
  parseError,
  multiMutationGetter,
} from 'utils/request';

import { deliveryActionsTypes, deliveryActions } from './actions';

import { queryConfig } from './queries';
import {
  selectErrors,
  selectEntityName,
  selectSelectedProject,
  selectCurrentEmployeeDetails,
} from './selectors';

function* deleteVacationManagementRecord({
  payload: {
    vacationManagementId,
    devstaffId,
  },
}) {
  try {
    const {
      deleteVacationManagementRecordError,
    } = yield select(selectErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.deleteVacationManagementRecord({
      vacationManagementId,
      devstaffId,
    });

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      errors,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: deleteVacationManagementRecordError,
        containerId: 'confirmActionModal',
      }); // TODO change containerID

      return yield put(deliveryActions.deleteVacationManagementRecordFail({
        error: {
          deleteVacationManagementRecordError: listErrors,
        },
      }));
    }

    if (deleteVacationManagementRecordError) {
      deleteVacationManagementRecordError.forEach((error) => toast.dismiss(error));
    }

    return yield all([
      put(deliveryActions.deleteVacationManagementRecordSuccess()),
      devstaffId && put(deliveryActions.checkUpdatesEmployeesDetails({
        devstaffId,
      })),
    ]);
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: 'managePtoModal',
    });
    return yield put(deliveryActions.deleteVacationManagementRecordFail({
      error: {
        deleteVacationManagementRecordError: [message || 'unknown'],
      },
    }));
  }
}

function* generateResourceBillingReport({ payload }) {
  try {
    const mutation = queryConfig.generateResourceBillingReport;

    const options = {
      mutation,
      variables: {
        devstaffId: payload.devstaffId,
      },
    };

    const { generateResourceBillingReport: response } = yield call(executeMutation, options);

    window.open(response.url, '_blank');

    return yield put(deliveryActions.generateResourceBillingReportSuccess());
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generateResourceBillingReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const generateResourceBillingReportError = yield call(parseError, error, options);

    return yield put(deliveryActions.generateResourceBillingReportFail({
      error: {
        generateResourceBillingReportError,
      },
    }));
  }
}

function* createVacationManagementRecord({
  payload: {
    fields,
    vacationByYearId,
    devstaffId,
  },
}) {
  try {
    const ptoDays = get(fields, 'ptoDays', []);
    const records = ptoDays.map((record) => ({
      ...record,
      vacationByYearId,
      devstaffId,
    }));
    const multiMutationOptions = multiMutationGetter({
      inputType: 'VacationActionInput!',
      mutation: 'createVacationManagementRecord',
      idKeys: ['devstaffId', 'vacationByYearId'],
      records,
    });
    yield call(executeMutation, multiMutationOptions);

    return yield all([
      put(deliveryActions.createVacationManagementRecordSuccess()),
      devstaffId && put(deliveryActions.checkUpdatesEmployeesDetails({
        devstaffId,
      })),
    ]);
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'createVacationManagementRecordError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const createVacationManagementRecordError = yield call(parseError, error, options);
    return yield put(deliveryActions.createVacationManagementRecordFail({
      error: {
        createVacationManagementRecordError,
      },
    }));
  }
}
function* deleteEmployeesRecord({ payload: { devstaffId } }) {
  try {
    const mutation = queryConfig.deleteDevstaff;
    const options = {
      mutation,
      variables: {
        devstaffId,
      },
    };
    yield call(executeMutation, options);
    return yield all([
      put(deliveryActions.deleteEmployeesRecordSuccess()),
      put(push({
        pathname: '/delivery/employees-list',
      })),
    ]);
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'deleteEmployeesRecordError', []);
    const options = {
      entityName,
      storedErrors,
      errorMessage: get(error, 'extensions.detailed', null),
    };
    const deleteEmployeesRecordError = yield call(parseError, error, options);

    return yield put(deliveryActions.deleteEmployeesRecordFail({
      error: { deleteEmployeesRecordError },
    }));
  }
}
function* checkUpdatesEmployeesDetails({
  payload: {
    devstaffId,
  },
}) {
  const entityName = yield select(selectEntityName);

  try {
    const {
      checkUpdatesEmployeesDetailsError,
    } = yield select(selectErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.checkUpdatesEmployeesDetails(devstaffId);

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      data,
      errors,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: checkUpdatesEmployeesDetailsError,
        containerId: entityName,
      });

      return yield put(deliveryActions.checkUpdatesEmployeesDetailsFail({
        error: {
          checkUpdatesEmployeesDetailsError: listErrors,
        },
      }));
    }

    const {
      node,
    } = data;

    if (checkUpdatesEmployeesDetailsError) {
      checkUpdatesEmployeesDetailsError.forEach((error) => toast.dismiss(error));
    }

    return yield put(deliveryActions.checkUpdatesEmployeesDetailsSuccess({
      employeeDetails: node,
    }));
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });
    return yield put(deliveryActions.checkUpdatesEmployeesDetailsFail({
      error: {
        checkUpdatesEmployeesDetailsError: [message || 'unknown'],
      },
    }));
  }
}

// eslint-disable-next-line consistent-return
function* createEmployee({ payload }) {
  const entityName = yield select(selectEntityName);
  try {
    const mutation = queryConfig.createEmployee();
    const options = {
      mutation,
      variables: {
        fields: {
          ...payload.fields,
          name: generateShortNameByFullName(get(payload, 'fields.fullname')),
        },
      },
    };

    const { createDevstaffv2 } = yield call(executeMutation, options);

    yield put(deliveryActions.createEmployeeSuccess({ devstaffId: createDevstaffv2.devstaff.devstaffId }));
    yield put(push(`/delivery/employee/${createDevstaffv2.devstaff.devstaffId}/work-book`));
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });

    return yield put(deliveryActions.createEmployeeFail({
      error: {
        createEmployeeError: message,
      },
    }));
  }
}

function* updateWorkbookRecord({
  payload: {
    fields,
    initialValues,
    recordId,
    devstaffId,
  },
  meta: {
    entityName,
  },
}) {
  try {
    const {
      updateWorkbookRecordError,
    } = yield select(selectErrors);
    const withModal = yield !!select(selectCurrentModal);

    const devCentersBySystemRole = yield select(selectDevCentersByUserGroup);
    const systemRole = yield select(selectUserGroup);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.updateWorkbookRecord(recordId);

    const parsedValues = getParsedValues(fields, initialValues);

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
      variables: {
        fields: parsedValues,
      },
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      errors,
      data,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: updateWorkbookRecordError,
        containerId: entityName,
      }); // TODO change containerID

      return yield put(deliveryActions.updateWorkbookRecordFail({
        error: {
          updateWorkbookRecordError: listErrors,
        },
      }));
    }

    if (updateWorkbookRecordError) {
      updateWorkbookRecordError.forEach((error) => toast.dismiss(error));
    }

    const workBookRecord = get(data, 'updateWorkBookRecord.workBookRecord', null);

    return yield all([
      put(deliveryActions.updateWorkbookRecordSuccess({
        entityName,
        withModal,
        fields: workBookRecord,
        recordId,
        devCentersBySystemRole,
        systemRole,
      })),
      devstaffId && put(deliveryActions.checkUpdatesEmployeesDetails({
        devstaffId,
      })),
    ]);
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });
    return yield put(deliveryActions.updateWorkbookRecordFail({
      error: {
        updateWorkbookRecordError: [message || 'unknown'],
      },
    }));
  }
}

function* createWorkbookRecord({
  payload: {
    devstaffId,
    fields,
  },
}) {
  const entityName = yield select(selectEntityName);

  try {
    const {
      createWorkbookRecordError,
    } = yield select(selectErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.createWorkbookRecord(devstaffId);

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
      variables: {
        fields,
      },
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      errors,
      data,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: createWorkbookRecordError,
        containerId: entityName,
      }); // TODO change containerID

      return yield put(deliveryActions.createWorkbookRecordFail({
        error: {
          createWorkbookRecordError: listErrors,
        },
      }));
    }

    if (createWorkbookRecordError) {
      createWorkbookRecordError.forEach((error) => toast.dismiss(error));
    }

    const workBookRecord = get(data, 'createWorkBookRecord.workBookRecord', null);

    return yield all([
      put(deliveryActions.createWorkbookRecordSuccess({
        fields: workBookRecord,
      })),
      devstaffId && put(deliveryActions.checkUpdatesEmployeesDetails({
        devstaffId,
      })),
    ]);
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });
    return yield put(deliveryActions.createWorkbookRecordFail({
      error: {
        createWorkbookRecordError: [message || 'unknown'],
      },
    }));
  }
}

function* deleteWorkbookRecord({
  payload: {
    devstaffId,
    recordId,
  },
}) {
  try {
    const {
      deleteWorkbookRecordError,
    } = yield select(selectErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.deleteWorkbookRecord({
      devstaffId,
      recordId,
    });

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      errors,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: deleteWorkbookRecordError,
        containerId: 'confirmActionModal',
      }); // TODO change containerID

      return yield put(deliveryActions.deleteWorkbookRecordFail({
        error: {
          deleteWorkbookRecordError: listErrors,
        },
      }));
    }

    if (deleteWorkbookRecordError) {
      deleteWorkbookRecordError.forEach((error) => toast.dismiss(error));
    }

    return yield all([
      put(deliveryActions.deleteWorkbookRecordSuccess({
        recordId,
      })),
      devstaffId && put(deliveryActions.checkUpdatesEmployeesDetails({
        devstaffId,
      })),
    ]);
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: 'confirmActionModal',
    });
    return yield put(deliveryActions.deleteWorkbookRecordFail({
      error: {
        deleteWorkbookRecordError: [message || 'unknown'],
      },
    }));
  }
}

function* updateOnboardingHistory({
  payload: {
    historyId,
    fields,
  },
  meta: {
    isIntermediateStage,
  },
}) {
  const entityName = yield select(selectEntityName);

  try {
    const {
      dateStart,
    } = fields;
    const {
      updateOnboardingHistoryError,
    } = yield select(selectErrors);
    const {
      devstaffId,
    } = yield select(selectCurrentEmployeeDetails);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = dateStart ? queryConfig.updateOnboardingHistory(historyId) : queryConfig.deleteOnboardingHistory(historyId);

    const variables = dateStart ? {
      fields,
    } : null;

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
      variables,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      errors,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: updateOnboardingHistoryError,
        containerId: entityName,
      });

      return yield put(deliveryActions.updateOnboardingHistoryFail({
        error: {
          updateOnboardingHistoryError: listErrors,
        },
      }));
    }

    if (updateOnboardingHistoryError) {
      updateOnboardingHistoryError.forEach((error) => toast.dismiss(error));
    }

    if (isIntermediateStage) {
      return null;
    }

    return yield all([
      put(deliveryActions.updateEmployeeDetails({
        devstaffId,
      })),
    ]);
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });
    return yield put(deliveryActions.updateOnboardingHistoryFail({
      error: {
        updateOnboardingHistoryError: [message || 'unknown'],
      },
    }));
  }
}

function* createOnboardingHistories({
  payload: {
    devstaffId,
    fields,
    initialValues,
  },
}) {
  const entityName = yield select(selectEntityName);

  try {
    const {
      createOnboardingHistoryError,
    } = yield select(selectErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.createOnboardingHistory(devstaffId);

    const parsedValues = getParsedValues(fields, initialValues);

    const variables = {
      fields: parsedValues,
    };

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
      variables,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      errors,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: createOnboardingHistoryError,
        containerId: entityName,
      });

      return yield put(deliveryActions.createOnboardingHistoryFail({
        error: {
          createOnboardingHistoryError: listErrors,
        },
      }));
    }

    if (createOnboardingHistoryError) {
      createOnboardingHistoryError.forEach((error) => toast.dismiss(error));
    }

    return yield all([
      put(deliveryActions.updateEmployeeDetails({
        devstaffId,
      })),
    ]);
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });
    return yield put(deliveryActions.createOnboardingHistoryFail({
      error: {
        createOnboardingHistoryError: [message || 'unknown'],
      },
    }));
  }
}

function* updateEmployeeDetails({
  payload: {
    devstaffId,
    fields,
    initialValues,
  },
}) {
  try {
    const {
      passedOnboardingHistories: passedOnboardingHistoriesInitial,
    } = initialValues;
    const {
      passedOnboardingHistories,
      ...rest
    } = getParsedValues(fields, initialValues);
    const updatedHistoryRecords = differenceWith(passedOnboardingHistories, passedOnboardingHistoriesInitial, isEqual);
    const mutation = queryConfig.updateEmployeeDetails(devstaffId);
    const variables = {
      fields: rest,
    };
    const options = {
      mutation,
      variables,
    };

    if (updatedHistoryRecords.length) {
      const multiMutationOptions = multiMutationGetter({
        mutation: 'updateOnboardingHistory',
        idKeys: 'historyId',
        records: updatedHistoryRecords,
        inputType: 'OnboardingHistoryInput!',
      });

      yield call(executeMutation, multiMutationOptions);
    }

    const response = yield call(executeMutation, options);
    const devstaff = get(response, 'updateDevstaffv2.devstaff', {});
    const hasDateHiredChanged = has(rest, 'dateHired');

    if (hasDateHiredChanged) {
      yield call(getEmployeeDetails, {
        payload: {
          employeeId: devstaffId,
        },
      });
    }

    return yield all([
      put(deliveryActions.updateEmployeeDetailsSuccess({
        devstaffId,
        fields: {
          ...rest,
          ...devstaff,
        },
      })),
    ]);
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'updateEmployeeDetailsError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const updateEmployeeDetailsError = yield call(parseError, error, options);

    return yield put(deliveryActions.updateEmployeeDetailsFail({
      error: {
        updateEmployeeDetailsError,
      },
    }));
  }
}

// eslint-disable-next-line consistent-return
function* getEmployeeDetails({
  payload: {
    employeeId,
  },
}) {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getEmployeeDetailsError', []);
  const userGroup = yield select(selectUserGroup);
  const hasPermissionsPlAssignment = getHasPermissions(userGroup, permissionsToProjectLeadAssignmentsTab);
  try {
    let employeeDetailsResult = {};
    let employeeDetailsTabsResult = {};
    if (employeeId) {
      const employeeDetailsResponse = yield call(executeQuery, {
        query: queryConfig.getEmployeeDetails(employeeId, hasPermissionsPlAssignment),
      });

      const { node } = employeeDetailsResponse;
      const {
        attachments,
        contracts,
        comments,
        contacts,
        technicalMentor,
        ...data
      } = node;

      employeeDetailsTabsResult = {
        attachments: get(attachments, 'length', 0),
        contracts: get(contracts, 'length', 0),
        comments: get(comments, 'length', 0),
        contacts: get(contacts, 'length', 0),
        technicalMentors: get(technicalMentor, 'length', 0),
      };

      employeeDetailsResult = {
        ...data,
        ptoRequestRecords: get(node, 'ptoRequestRecords', []).map((ptoRequestRecord) => ({
          ...ptoRequestRecord,
          plApprovals: ptoRequestRecord.plApprovals,
          plAssignments: get(node, 'plAssignments', []).map((plAssignment) => {
            const {
              internalCategoryKey,
              internalCategoryName,
              ...rest
            } = plAssignment;

            return ({
              projectKey: internalCategoryKey,
              projectName: internalCategoryName,
              ...rest,
            });
          }),
        })),
      };
    }

    const resourceCommonDetailsResponse = yield call(executeQuery, {
      query: queryConfig.getResourceCommonDetails(employeeId),
    });

    const {
      technologies,
      allInternalCategoriesForPl,
      holidays,
      devcenters,
      devcentersAll,
      titlesFromDevstaffCompensation,
      devstaffList,
    } = resourceCommonDetailsResponse;

    const employeeDetails = {
      ...employeeDetailsResult,
      titlesFromDevstaffCompensation,
      technicalMentorsList: devstaffList.filter((item) => item.value.toString() !== employeeId),
      technologiesList: technologies,
      projects: allInternalCategoriesForPl,
      holidays,
      devcenters: combineDevCenters(employeeId ? devcentersAll : devcenters),
      devcentersAll,
    };

    return yield put(deliveryActions.getEmployeeDetailSuccess({
      employeeDetails,
      employeeDetailsTabs: employeeDetailsTabsResult,
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);

    const options = {
      entityName,
      storedErrors,
    };
    const getEmployeeDetailsError = yield call(parseError, error, options);

    return yield put(deliveryActions.getEmployeeDetailFail({
      error: {
        getEmployeeDetailsError,
      },
    }));
  }
}

function* getEmployeesList() {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getEmployeesListError', []);

  try {
    const query = queryConfig.getEmployeesList;
    const options = {
      query,
    };

    const {
      employeesList = [],
    } = yield call(executeQuery, options);

    return yield put(deliveryActions.getEmployeesListSuccess({
      employeesList,
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);

    const options = {
      entityName,
      storedErrors,
    };
    const getEmployeesListError = yield call(parseError, error, options);

    return yield put(deliveryActions.getEmployeesListFail({
      error: {
        getEmployeesListError,
      },
    }));
  }
}

function* getProjectsList() {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getProjectsListError', []);

  try {
    const query = queryConfig.getProjectsList;
    const options = {
      query,
    };

    const {
      projectsList = [],
      plList = [],
    } = yield call(executeQuery, options);

    return yield put(deliveryActions.getProjectsListSuccess({
      projectsList,
      plList,
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);

    const options = {
      entityName,
      storedErrors,
    };
    const getProjectsListError = yield call(parseError, error, options);

    return yield put(deliveryActions.getProjectsListFail({
      error: {
        getProjectsListError,
      },
    }));
  }
}

/* PTO sagas */

// TODO: consider make common deleteEntityRecord saga, as logic is duplicated
function* deletePtoRequestRecord({
  payload: {
    devstaffId,
    recordId,
  },
}) {
  try {
    const {
      deletePtoRequestRecordError,
    } = yield select(selectErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.deletePtoRequestRecord({
      devstaffId,
      recordId,
    });

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      errors,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: deletePtoRequestRecordError,
        containerId: 'confirmActionModal',
      }); // TODO change containerID

      return yield put(deliveryActions.deletePtoRequestRecordFail({
        error: {
          deletePtoRequestRecordError: listErrors,
        },
      }));
    }

    if (deletePtoRequestRecordError) {
      deletePtoRequestRecordError.forEach((error) => toast.dismiss(error));
    }

    return yield all([
      put(deliveryActions.deletePtoRequestRecordSuccess({
        recordId,
      })),
      devstaffId && put(deliveryActions.checkUpdatesEmployeesDetails({
        devstaffId,
      })),
    ]);
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: 'confirmActionModal',
    });
    return yield put(deliveryActions.deletePtoRequestRecordFail({
      error: {
        deletePtoRequestRecordError: [message || 'unknown'],
      },
    }));
  }
}
function* createPtoRequestRecord({
  payload: {
    devstaffId,
    fields,
  },
}) {
  const entityName = yield select(selectEntityName);

  try {
    const {
      createPtoRequestRecordError,
    } = yield select(selectErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.createPtoRequestRecord(devstaffId);

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
      variables: {
        fields,
      },
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      errors,
      data,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: createPtoRequestRecordError,
        containerId: entityName,
      });

      return yield put(deliveryActions.createPtoRequestRecordFail({
        error: {
          createPtoRequestRecordError: listErrors,
        },
      }));
    }

    if (createPtoRequestRecordError) {
      createPtoRequestRecordError.forEach((error) => toast.dismiss(error));
    }

    const ptoRequestRecord = get(data, 'createPtoRequestRecord.ptoRequestRecord', null);

    return yield all([
      put(deliveryActions.createPtoRequestRecordSuccess({
        fields: {
          ...ptoRequestRecord,
          plApprovals: ptoRequestRecord.plApprovals,
        },
      })),
      devstaffId && put(deliveryActions.checkUpdatesEmployeesDetails({
        devstaffId,
      })),
    ]);
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });
    return yield put(deliveryActions.createPtoRequestRecordFail({
      error: {
        createPtoRequestRecordError: [message || 'unknown'],
      },
    }));
  }
}

function* updatePlApprovals({
  initialPlApprovals,
  plApprovals,
  recordId,
}) {
  const addedFields = [];
  const removedFields = [];
  const updatedFields = [];
  const fieldsMap = {};

  if (!isEmpty(plApprovals)) {
    plApprovals.forEach((record) => {
      if (record) {
        const {
          plApprovalId,
          ...rest
        } = record;

        if (!plApprovalId) {
          addedFields.push({
            ...record,
            recordId,
          });
        } else {
          fieldsMap[plApprovalId] = rest;
        }
      }
    });

    !isEmpty(initialPlApprovals) && initialPlApprovals.forEach((initialRecord) => {
      if (initialRecord) {
        const {
          plApprovalId,
          ...rest
        } = initialRecord;
        const record = fieldsMap[plApprovalId];

        if (plApprovalId) {
          if (!record) {
            removedFields.push({
              plApprovalId,
              recordId,
            });
          } else if (!isEqual(record, rest)) {
            updatedFields.push({
              plApprovalId,
              ...record,
            });
          }
        }
      }
    });
  }

  if (addedFields.length) {
    const multiMutationOptions = multiMutationGetter({
      mutation: 'createPlApproval',
      idKeys: 'recordId',
      records: addedFields,
      inputType: 'PlApprovalInput!',
    });

    yield call(executeMutation, multiMutationOptions);
  }

  if (updatedFields.length) {
    const multiMutationOptions = multiMutationGetter({
      mutation: 'updatePlApproval',
      idKeys: 'plApprovalId',
      records: updatedFields,
      inputType: 'UpdatePlApprovalInput!',
    });

    yield call(executeMutation, multiMutationOptions);
  }

  if (removedFields.length) {
    const multiMutationOptions = multiMutationGetter({
      mutation: 'deletePlApproval',
      withoutFields: true,
      idKeys: ['plApprovalId', 'recordId'],
      records: removedFields,
    });

    yield call(executeMutation, multiMutationOptions);
  }
}

function* updatePtoRequestRecord({
  payload: {
    devstaffId,
    fields,
    initialValues,
    recordId,
  },
  meta: {
    entityName,
  },
}) {
  try {
    const withModal = yield !!select(selectCurrentModal);
    const mutation = queryConfig.updatePtoRequestRecord(recordId);

    const initialPlApprovals = get(initialValues, 'plApprovals', []);
    const {
      plApprovals,
      ...parsedValues
    } = getParsedValues(fields, initialValues);

    yield call(updatePlApprovals, {
      initialPlApprovals: !isEmpty(initialPlApprovals) ? initialPlApprovals : [],
      ...(!isEmpty(plApprovals) && {
        plApprovals,
      }),
      recordId,
    });

    const options = {
      mutation,
      variables: {
        fields: parsedValues,
      },
    };
    const response = yield call(executeMutation, options);
    const ptoRequestRecord = get(response, 'updatePtoRequestRecord.ptoRequestRecord', null);

    return yield all([
      put(deliveryActions.updatePtoRequestRecordSuccess({
        withModal,
        fields: {
          ...ptoRequestRecord,
          plApprovals: ptoRequestRecord.plApprovals,
        },
        recordId,
      })),
      devstaffId && put(deliveryActions.checkUpdatesEmployeesDetails({
        devstaffId,
      })),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'updatePtoRequestRecordError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const updatePtoRequestRecordError = yield call(parseError, error, options);

    return yield put(deliveryActions.updatePtoRequestRecordFail({
      error: {
        updatePtoRequestRecordError,
      },
    }));
  }
}

function* generatePlApprovals({
  payload: {
    devstaffId,
    recordId,
  },
}) {
  const entityName = yield select(selectEntityName);

  try {
    const {
      generatePlApprovalsError,
    } = yield select(selectErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.generatePlApprovals(devstaffId, recordId);

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      errors,
      data,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: generatePlApprovalsError,
        containerId: entityName,
      }); // TODO change containerID

      return yield put(deliveryActions.generatePlApprovalsFail({
        error: {
          generatePlApprovalsError: listErrors,
        },
      }));
    }

    if (generatePlApprovalsError) {
      generatePlApprovalsError.forEach((error) => toast.dismiss(error));
    }

    const plApprovals = get(data, 'generatePlApprovals.plApprovals', []);

    return yield all([
      put(deliveryActions.generatePlApprovalsSuccess({
        fields: plApprovals,
        recordId,
      })),
    ]);
  } catch (error) {
    const {
      message,
    } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });
    return yield put(deliveryActions.generatePlApprovalsFail({
      error: {
        generatePlApprovalsError: [message || 'unknown'],
      },
    }));
  }
}

function* getAssignedPLs({ payload }) {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'getAssignedPLsError', []);

  try {
    const query = queryConfig.getAssignedPLs;
    const options = {
      query,
      variables: payload,
    };
    const { projectLeads } = yield call(executeQuery, options);

    return yield put(deliveryActions.getAssignedPLsSuccess({ projectLeads }));
  } catch (error) {
    const entityName = yield select(selectEntityName);

    const options = {
      entityName,
      storedErrors,
    };
    const getAssignedPLsError = yield call(parseError, error, options);

    return yield put(deliveryActions.getAssignedPLsFail({
      error: {
        getAssignedPLsError,
      },
    }));
  }
}

function* addDelegateToProject({ fields }) {
  const mutation = queryConfig.addDelegateToProject;
  const options = {
    mutation,
    variables: {
      fields,
    },
  };

  yield call(executeMutation, options);
}

function* assignPlToProject({ fields, delegates }) {
  const mutation = queryConfig.assignPlToProject;
  const options = {
    mutation,
    variables: {
      fields,
    },
  };
  const response = yield call(executeMutation, options);
  const plaId = get(response, 'assignPlToProject.plaId');

  if (delegates) {
    yield all(delegates.map(({ id, plLevel, ...rest }) => call(addDelegateToProject, ({ fields: { ...rest, plaId } }))));
  }
}

function* updatePLAssignments({
  payload: {
    fields,
    initialValues,
  },
}) {
  try {
    const addedFields = [];
    const updatedFields = [];
    const addedDelegates = [];
    const updatedDelegates = [];
    const fieldsMap = {};
    const delegatesFieldsMap = {};
    const initialProjectLeadsRecords = get(initialValues, 'projectLeads', []);
    const projectLeadsRecords = get(fields, 'projectLeads', []);
    const internalCategoryKey = yield select(selectSelectedProject);
    const checkDelegateRecordsUpdates = ({ plaId, initial, current = [] }) => {
      current.forEach((record) => {
        const {
          id,
        } = record;
        if (!id) {
          addedDelegates.push({
            plaId,
            ...record,
          });
        } else {
          delegatesFieldsMap[id] = record;
        }
      });

      initial.forEach((initialRecord) => {
        const { id } = initialRecord;

        if (id) {
          const record = delegatesFieldsMap[id];

          if (record && !isEqual(initialRecord, record)) {
            const { id: recordId, plLevel, ...rest } = record;

            updatedDelegates.push({
              recordId,
              ...rest,
            });
          }
        }
      });
    };

    projectLeadsRecords.forEach((record) => {
      if (record) {
        const {
          recordId,
          ...rest
        } = record;

        if (!recordId) {
          addedFields.push(record);
        } else {
          fieldsMap[recordId] = rest;
        }
      }
    });

    initialProjectLeadsRecords.forEach((initialRecord) => {
      if (initialRecord) {
        const { recordId } = initialRecord;

        if (recordId) {
          const record = fieldsMap[recordId];

          if (record) {
            const updatedValues = {};
            let hasUpdates = false;

            Object.entries(record).forEach(([key, value]) => {
              const initialValue = get(initialRecord, key);

              if (!isEqual(initialValue, value)) {
                switch (key) {
                  case 'delegates':
                    checkDelegateRecordsUpdates({ plaId: recordId, initial: initialValue, current: value });
                    break;
                  case 'percentage':
                    hasUpdates = true;
                    if (value === null) {
                      updatedValues[key] = -1;
                      break;
                    }
                    updatedValues[key] = value;
                    break;
                  default:
                    hasUpdates = true;
                    updatedValues[key] = value;
                    break;
                }
              }
            });

            if (hasUpdates) {
              updatedFields.push({
                recordId,
                ...updatedValues,
              });
            }
          }
        }
      }
    });

    if (addedFields.length) {
      yield all(addedFields.map(({ delegates, ...rest }) => call(assignPlToProject, ({ delegates, fields: rest }))));
    }

    if (updatedFields.length) {
      const multiMutationOptions = multiMutationGetter({
        mutation: 'updateAssignedPl',
        idKeys: ['recordId'],
        inputType: 'UpdateAssignedPLInput!',
        records: updatedFields,
      });

      yield call(executeMutation, multiMutationOptions);
    }

    if (updatedDelegates.length) {
      const multiMutationOptions = multiMutationGetter({
        mutation: 'updateDelegate',
        idKeys: ['recordId'],
        inputType: 'UpdateDelegateInput!',
        records: updatedDelegates,
      });

      yield call(executeMutation, multiMutationOptions);
    }

    if (addedDelegates.length) {
      const multiMutationOptions = multiMutationGetter({
        mutation: 'addDelegate',
        idKeys: [],
        inputType: 'AddDelegateInput!',
        records: addedDelegates,
      });

      yield call(executeMutation, multiMutationOptions);
    }

    const query = queryConfig.getSingleProjectData(internalCategoryKey);
    const options = {
      query,
    };
    const { updatedProject } = yield call(executeQuery, options);

    return yield all([
      put(deliveryActions.updatePLAssignmentsSuccess({
        internalCategoryKey,
        updatedProject,
      })),
    ]);
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'updatePLAssignmentsError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const updatePLAssignmentsError = yield call(parseError, error, options);

    return yield put(deliveryActions.updatePLAssignmentsFail({
      error: {
        updatePLAssignmentsError,
      },
    }));
  }
}

function* deletePLARecord({
  payload: {
    recordId,
    projectKey,
    mutationName,
  },
  meta: {
    entityName,
  },
}) {
  try {
    const mutation = queryConfig.deletePLARecord({ mutationName, recordId });

    const options = {
      mutation,
    };
    yield call(executeMutation, options);

    const query = queryConfig.getSingleProjectData(projectKey);
    const queryOptions = {
      query,
    };
    const { updatedProject } = yield call(executeQuery, queryOptions);

    return yield all([
      put(modalConductorActions.changeCurrentModal({ currentModal: null })),
      put(deliveryActions.deletePLARecordSuccess({
        projectKey,
        updatedProject,
      })),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'deletePLARecordError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const deletePLARecordError = yield call(parseError, error, options);

    return yield put(deliveryActions.deletePLARecordFail({
      error: {
        deletePLARecordError,
      },
    }));
  }
}

function* checkEmployeeEmail({
  payload,
}) {
  const entityName = yield select(selectEntityName);
  const errors = yield select(selectErrors);

  dismissErrorsById(errors, 'checkEmployeeEmailError');

  try {
    const errorMessage = 'Such email already exists. Please make updates.';
    const query = queryConfig.checkDevstaffEmail;
    const options = {
      query,
      variables: {
        email: get(payload, 'email', ''),
      },
    };
    const { checkDevstaffEmail } = yield call(executeQuery, options);
    const isFree = get(checkDevstaffEmail, 'isFree', false);

    if (isFree) {
      return yield put(deliveryActions.checkEmployeeEmailSuccess({
        isEmployeeEmailAvailable: isFree,
        employeeDetails: payload,
      }));
    }

    getNotifications({
      errorMessage,
      containerId: entityName,
    });

    return yield put(deliveryActions.checkEmployeeEmailFail({
      error: {
        checkEmployeeEmailError: [errorMessage],
      },
    }));
  } catch (error) {
    const errorMessage = get(error, 'extensions.detailed', null);

    getNotifications({
      errorMessage,
      containerId: entityName,
    });

    return yield put(deliveryActions.checkEmployeeEmailFail({
      error: {
        checkEmployeeEmailError: [errorMessage],
      },
    }));
  }
}

function* checkUpdatesEmployeesDetailsWatcher() {
  yield takeLatest(deliveryActionsTypes.CHECK_UPDATES_EMPLOYEES_DETAILS, waitForAuthorization(checkUpdatesEmployeesDetails));
}

function* createEmployeeWatcher() {
  yield takeLatest(deliveryActionsTypes.CREATE_EMPLOYEE, waitForAuthorization(createEmployee));
}

function* updateWorkbookRecordWatcher() {
  yield takeLatest(deliveryActionsTypes.UPDATE_WORKBOOK_RECORD, waitForAuthorization(updateWorkbookRecord));
}

function* createWorkbookRecordWatcher() {
  yield takeLatest(deliveryActionsTypes.CREATE_WORKBOOK_RECORD, waitForAuthorization(createWorkbookRecord));
}

function* deleteWorkbookRecordWatcher() {
  yield takeLatest(deliveryActionsTypes.DELETE_WORKBOOK_RECORD, waitForAuthorization(deleteWorkbookRecord));
}

function* updateOnboardingHistoryWatcher() {
  yield takeLatest(deliveryActionsTypes.UPDATE_ONBOARDING_HISTORY, waitForAuthorization(updateOnboardingHistory));
}

function* createOnboardingHistoriesWatcher() {
  yield takeLatest(deliveryActionsTypes.CREATE_ONBOARDING_HISTORY, waitForAuthorization(createOnboardingHistories));
}

function* updateEmployeeDetailsWatcher() {
  yield takeLatest(deliveryActionsTypes.UPDATE_EMPLOYEES_DETAILS, waitForAuthorization(updateEmployeeDetails));
}

function* getEmployeeDetailsWatcher() {
  yield takeLatest(deliveryActionsTypes.GET_EMPLOYEES_DETAILS, waitForAuthorization(getEmployeeDetails));
}

function* getEmployeesListWatcher() {
  yield takeLatest(deliveryActionsTypes.GET_EMPLOYEES_LIST, waitForAuthorization(getEmployeesList));
}

function* getProjectsListWatcher() {
  yield takeLatest(deliveryActionsTypes.GET_PROJECTS_LIST, waitForAuthorization(getProjectsList));
}

function* deletePtoRequestRecordWatcher() {
  yield takeLatest(deliveryActionsTypes.DELETE_PTO_REQUEST_RECORD, waitForAuthorization(deletePtoRequestRecord));
}

function* createPtoRequestRecordWatcher() {
  yield takeLatest(deliveryActionsTypes.CREATE_PTO_REQUEST_RECORD, waitForAuthorization(createPtoRequestRecord));
}

function* updatePtoRequestRecordWatcher() {
  yield takeLatest(deliveryActionsTypes.UPDATE_PTO_REQUEST_RECORD, waitForAuthorization(updatePtoRequestRecord));
}

function* generatePlApprovalsWatcher() {
  yield takeLatest(deliveryActionsTypes.GENERATE_PL_APPROVALS, waitForAuthorization(generatePlApprovals));
}

function* createVacationManagementRecordWatcher() {
  yield takeLatest(deliveryActionsTypes.CREATE_VACATION_MANAGEMENT_RECORD, waitForAuthorization(createVacationManagementRecord));
}

function* deleteVacationManagementRecordWatcher() {
  yield takeLatest(deliveryActionsTypes.DELETE_VACATION_MANAGEMENT_RECORD, waitForAuthorization(deleteVacationManagementRecord));
}

function* deleteEmployeesRecordWatcher() {
  yield takeLatest(deliveryActionsTypes.DELETE_EMPLOYEES_RECORD, waitForAuthorization(deleteEmployeesRecord));
}

function* getAssignedPLsWatcher() {
  yield takeLatest(
    [
      deliveryActionsTypes.GET_ASSIGNED_PLS,
      deliveryActionsTypes.DELETE_PLA_RECORD_SUCCESS,
      deliveryActionsTypes.UPDATE_PL_ASSIGNMENTS_SUCCESS,
    ],
    waitForAuthorization(getAssignedPLs),
  );
}

function* updatePLAssignmentsWatcher() {
  yield takeLatest(deliveryActionsTypes.UPDATE_PL_ASSIGNMENTS, waitForAuthorization(updatePLAssignments));
}

function* deletePLARecordWatcher() {
  yield takeLatest(deliveryActionsTypes.DELETE_PLA_RECORD, waitForAuthorization(deletePLARecord));
}

function* checkEmployeeEmailWatcher() {
  yield takeLatest(deliveryActionsTypes.SET_EMPLOYEE_DETAILS, waitForAuthorization(checkEmployeeEmail));
}

function* generateResourceBillingReportWatcher() {
  yield takeLatest(deliveryActionsTypes.GENERATE_RESOURCE_BILLING_REPORT, waitForAuthorization(generateResourceBillingReport));
}

export default [
  getAssignedPLsWatcher,
  getProjectsListWatcher,
  deletePLARecordWatcher,
  getEmployeesListWatcher,
  getEmployeeDetailsWatcher,
  updatePLAssignmentsWatcher,
  generatePlApprovalsWatcher,
  createEmployeeWatcher,
  updateWorkbookRecordWatcher,
  createWorkbookRecordWatcher,
  deleteWorkbookRecordWatcher,
  deleteEmployeesRecordWatcher,
  updateEmployeeDetailsWatcher,
  deletePtoRequestRecordWatcher,
  createPtoRequestRecordWatcher,
  updatePtoRequestRecordWatcher,
  updateOnboardingHistoryWatcher,
  createOnboardingHistoriesWatcher,
  checkUpdatesEmployeesDetailsWatcher,
  deleteVacationManagementRecordWatcher,
  createVacationManagementRecordWatcher,
  checkEmployeeEmailWatcher,
  generateResourceBillingReportWatcher,
  ...contractManagementSagas,
  ...commentManagementSagas,
  ...attachmentsSagas,
  ...contactDataManagementSagas,
  ...technicalMentorManagementSagas,
];
